import client from '@libs/HttpClient';
class UserRoleService {
    static listUserRoles(request) {
        const page_no = request.queryKey[1];
        const searchText = request.queryKey[2];
        const status = request.queryKey[3];
        return client.get(`/role?search=${searchText}&page=${page_no}&status=${status}`);
    }
    static viewUserRoles(params) {
        return client.get(`/role/${params}/edit`);
    }
    static updateUserRoles(request) {
        return client.put(`/role/${request.id}`, request);
    }
    static addUserRoles(request) {
        return client.post('/role', request);
    }
    static statusChangeRole(request) {
        return client.post(`/role/update-status/${request.id}`, request);
    }
    static deleteRole(request) {
        return client.delete(`/role/${request.id}`, request);
    }
}
export { UserRoleService };