import * as Yup from 'yup';
let validationSchema = Yup.object({
    name: Yup.string(('page.add_task_name_required')).trim().max(255, ('page.add_task_name_validation_max')).required(
        ('page.add_task_name_required'),
    ),
    sprint_id: Yup.string(('page.add_sprint_required')).required(
        ('page.add_sprint_required')
    ),
    task_status_type_id: Yup.string(('page.add_task_status_type_required')).required(
        ('page.add_task_status_type_required')
    ),
    original_estimate_time:  Yup.string(('page.add_original_estimate_time_required')).trim().max(255, ('page.add_original_estimate_time_validation_max')).required(
        ('page.add_original_estimate_time_required'),
    ),
    priority: Yup.string(('page.add_priority_required')).required(
        ('page.add_priority_required'),
    )
});
export default validationSchema;