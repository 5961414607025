import { useMutation, useQuery } from 'react-query';
import { TableColumnService } from '@api/TableColumnService';
import { toast } from 'react-toastify';
const onDefaultError = (error) => {
  toast.error(error.message);
};

//table column master

const useAddTableColumn = (projectId, databaseId, tableId, navigate) => { // Accept navigate as an argument
  const { mutate, isLoading } = useMutation(
    (requestData) => TableColumnService.addTableColumn(projectId, databaseId, tableId, requestData),
    {
      onSuccess: (response) => {
        toast.success(response.message);
        navigate(`/projects/view/${projectId}/database/${databaseId}/tables`); // Use navigate here
      },
      onError: onDefaultError
    }
  );

  const doAddTableColumn = async (values) => {
    await mutate(values);
  };

  return { doAddTableColumn, isLoading };
};

const useListTableColumn = (
  params,
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    ['table-column-list', [params]],
    () => TableColumnService.listTableColumn(params),
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};


const useUpdateTableColumn = (onSuccess, onError = onDefaultError) => {
  return useMutation(TableColumnService.updateTableColumn, {
    onSuccess,
    onError
  })
}

const useViewTableColumn = (projectId, databaseId, tableId, id, onSuccess, onError = onDefaultError) => {
  return useQuery('table-column-view', () => TableColumnService.viewTableColumn(projectId, databaseId, tableId, id), {
    onSuccess,
    onError
  });
};


const useDeleteTableColumn = (onSuccess, onError = onDefaultError) => {
  return useMutation(TableColumnService.deleteTableColumn, {
    onSuccess,
    onError
  });
};

const useGetDBFieldTypeList = (onSuccess, onError = onDefaultError) => {
  return useQuery('db-field-get-type', TableColumnService.getDBFieldType, {
    onSuccess,
    onError
  });
};

const useGetDBFieldTypeListFilter = (onSuccess, onError = onDefaultError) => {
  return useQuery('db-field-get-type-list', TableColumnService.getDBFieldTypeList, {
    onSuccess,
    onError
  });
};

const useGetTableList = (databaseId, onSuccess, onError = onDefaultError) => {
  return useQuery(['get-table', databaseId], () => TableColumnService.getTable(databaseId), {
    onSuccess,
    onError
  });
};

const useGetTableListFilter = (databaseId, onSuccess, onError = onDefaultError) => {
  return useQuery(['get-table-list', databaseId], () => TableColumnService.getTableList(databaseId), {
    onSuccess,
    onError
  });
};

const useGetTableColumnList = (tableId, onSuccess, onError = onDefaultError) => {
  return useQuery(['get-table-column', tableId], () => TableColumnService.getTableColumn(tableId), {
    onSuccess,
    onError
  });
};


const useGetTableColumnListFilter = (tableId, onSuccess, onError = onDefaultError) => {
  return useQuery(['get-table-column-list', tableId], () => TableColumnService.getTableColumnList(tableId), {
    onSuccess,
    onError
  });
};

const useReviewDatabaseTableColumn = (onSuccess, onError = onDefaultError) => {
  return useMutation(TableColumnService.reviewDatabaseTableColumn, {
    onSuccess,
    onError
  })
}

export {

  useAddTableColumn,
  useListTableColumn,
  useDeleteTableColumn,
  useUpdateTableColumn,
  useViewTableColumn,
  useGetDBFieldTypeListFilter,
  useGetDBFieldTypeList,
  useGetTableList,
  useGetTableListFilter,
  useGetTableColumnListFilter,
  useGetTableColumnList,
  useReviewDatabaseTableColumn

};

