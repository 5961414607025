import client from '@libs/HttpClient';
class TaskStatusTypeService {
  static listTaskStatusType(request) {
    const projectId = request.id;
    const page_no = request.page_no;
    const search = request.searchText;
    return client.get(
      `/projects/view/${projectId}/task-status-type?search=${search}&page=${page_no}`,
      request
    );
  }
  static deleteTaskStatusType(projectId, id) {
    return client.delete(`/projects/view/${projectId}/task-status-type/${id}`);
  }
  static addTaskStatusType(request) {
    const projectId = request.project_id;
    return client.post(`projects/view/${projectId}/task-status-type`, request);
  }
  static updateTaskStatusType(request) {
    const id = request.id;
    const projectId = request.project_id;
    return client.put(`/projects/view/${projectId}/task-status-type/${id}`, request);
  }
  static viewTaskStatusType(projectId,id) {
    return client.get(`/projects/view/${projectId}/task-status-type/${id}/edit`);
  }
  static getMaxSequenceNumber(projectId) {
    return client.get(`/get-max-sequence-no/${projectId}`);
  }
  static updateTaskStatusTypeStatus(request) {
    const id = request.id;
    const projectId = request.project_id;
    return client.put(`/projects/view/${projectId}/task-status-type/update-status/${id}`, request);
  }
}
export { TaskStatusTypeService };