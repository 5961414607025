import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
const ListBranchPage = React.lazy(() => import('./ListBranchPage'));
const AddBranchPage = React.lazy(() => import('./AddBranchPage'));
const EditBranchPage = React.lazy(() => import('./EditBranchPage'));
const BranchRoute = ({ t }) => {
  return (
    <Routes>
      <Route index exact path="/list" element={<ListBranchPage t={t} />} />
      <Route exact path="/add" element={<AddBranchPage t={t} />} />
      <Route exact path='/edit/:id' element={<EditBranchPage t={t}/>} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
BranchRoute.propTypes = {
  t: PropTypes.func
};
export default BranchRoute;