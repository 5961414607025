import * as Yup from 'yup';
let validationSchema = Yup.object({
    status_name: Yup.string(('page.add_project_name_required')).trim().required(
        ('page.project_task_status_name_required'),
    ),
    sequence: Yup.string(('page.add_project_name_required')).trim().required(
        ('page.project_task_status_sequence_required')),
    is_active: Yup.string(('page.add_project_name_required')).required(
        ('page.project_task_status_type_is_active_required')
    )
});
export default validationSchema;