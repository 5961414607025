import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
const ListFaqPage = React.lazy(() => import('./ListFaqPage'));
const AddFaqPage = React.lazy(() => import('./AddFaqPage'));
const EditFaqPage = React.lazy(() => import('./EditFaqPage'));
const FaqRoute = ({ t }) => {
  return (
    <Routes>
      <Route index exact path="/list" element={<ListFaqPage t={t} />} />
      <Route exact path="/add" element={<AddFaqPage t={t} />} />
      <Route exact path="/edit/:id" element={<EditFaqPage t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
FaqRoute.propTypes = {
  t: PropTypes.func
};
export default FaqRoute;