import client from '@libs/HttpClient';
class UserService {
  static getProfile(request) {
    return client.get('/user/get-profile', request);
  }
  static updatePassword(request) {
    return client.put('/user/change-password', request);
  }
  static updateProfile(request) {
    return client.post('/user/update-profile', request, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
  }
  static getCountryCodeList(request) {
    return client.get('/country-code-list', request);
  }
  static updateEmailVerify(request) {
    return client.post('/user/verify-email', request, {});
  }
  static updateEmail(request) {
    return client.post('/user/update-email', request, {});
  }
}
export { UserService };