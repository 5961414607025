import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClientProvider, QueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import PagesRoutes from '../routes';
import { persistor, store } from '../store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../assets/scss/style.scss';
import { setAuthToken, setAuthType } from '@libs/HttpClient';
import Loader from '@common/components/Loader';
import NoInternet from '@common/components/NoInternet';
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false
    }
  }
});
function App() {
  const { t } = useTranslation();
  // Internet Connectivity Check
  if (!navigator.onLine) {
    return <NoInternet />;
  }
  window.onoffline = () => {
    return <NoInternet />;
  };
  window.ononline = () => {
    window.location.reload(true);
  };
  const handleOnBeforeLift = () => {
    setAuthType('');
    if (
      store.getState().user.accessToken !== undefined &&
      store.getState().user.accessToken !== null
    ) {
      setAuthToken(store.getState().user.accessToken);
      setAuthType(store.getState().user.user.user_auth_type);
    }
  };
  return (
    <Suspense fallback={<Loader />}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor} onBeforeLift={handleOnBeforeLift}>
          <QueryClientProvider client={queryClient}>
            <PagesRoutes t={t} />
            <ToastContainer />
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </Suspense>
  );
}
export default App;