import { useMutation, useQuery } from 'react-query';
import { TaskStatusTypeService } from '@api/TaskStatusTypeService';
import { onDefaultError } from '../helpers/commonHelpers';
// Records
const useListTaskStatusType = ([ id, page_no, searchText], onSuccess, onError = onDefaultError ) => {
  return useQuery(
    ['task-status-type-list', id, page_no, searchText],
    () => TaskStatusTypeService.listTaskStatusType({ id, page_no, searchText }),
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};
// Delete
const useDeleteTaskStatusType = () => {
  const deleteTaskStatusType = async (projectId, id) => {
    return await TaskStatusTypeService.deleteTaskStatusType(projectId, id);
  };
  return { deleteTaskStatusType };
};
// Create
const useAddTaskStatusType = (onSuccess, onError = onDefaultError) => {
  return useMutation(TaskStatusTypeService.addTaskStatusType, {
    onSuccess,
    onError
  });
} 
// Update
const useUpdateTaskStatusType = (onSuccess, onError = onDefaultError) => {
  return useMutation(TaskStatusTypeService.updateTaskStatusType, {
    onSuccess,
    onError
  })
}
// Details
const useViewTaskStatusType = (projectId, id, onSuccess, onError = onDefaultError) => {
  return useQuery('task-status-type-view', () => TaskStatusTypeService.viewTaskStatusType(projectId, id), {
    onSuccess,
    onError
  });
};
// Set Sequence Number
const useGetMaxSequenceNumber = (projectId, onSuccess, onError = onDefaultError) => {
  return useQuery('max-sequence-number', () => TaskStatusTypeService.getMaxSequenceNumber(projectId), {
    onSuccess,
    onError
  });
};
// Status Change
const useStatusChangeTaskStatusType = (onSuccess, onError = onDefaultError) => {
  return useMutation(TaskStatusTypeService.updateTaskStatusTypeStatus, {
    onSuccess,
    onError
  });
};
export {
    useDeleteTaskStatusType, 
    useListTaskStatusType,
    useAddTaskStatusType,
    useUpdateTaskStatusType,
    useViewTaskStatusType,
    useGetMaxSequenceNumber,
    useStatusChangeTaskStatusType
};