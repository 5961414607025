// React Dependencies
import { useState, React } from "react";
// Common Dependencies
import { useParams, useNavigate } from 'react-router-dom';
import makeAnimated from 'react-select/animated';
import PropTypes from 'prop-types';
// Component
import { Button, Form, Card, Row, Col } from 'react-bootstrap';
import { TNButton } from '@common/components';
import Select from 'react-select';
import { ProjectNavigation } from '../Navigation/ProjectNavigation';
// CK Editor
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// API
import { useAddTask, useGetSprintList, useGetAssignedProjectEmployeeList, useGetTaskPriorities, useGetTaskStatusTypes } from '@hooks';
// Validation
import { useFormik } from 'formik';
import validationSchema from './AddEditTaskValidation';
// Helper
import { defaultValue } from '@helpers';
// Alert
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import CommonSEOTitle from "../../../../helpers/CommonSEOTitle";
const AddTaskPage = ({ t, socket }) => {
  // Navigate
  const navigate = useNavigate();
  // Project ID
  let { id } = useParams();
  // Animated Component Set
  const animatedComponents = makeAnimated();
  // States
  const [descriptionD, setDescriptionD] = useState('');
  // Dropdown Initialization
  // Get Sprint List
  const { data: optionList, isLoading: isSprintLoading } = useGetSprintList(id);
  let options = [];
  if (optionList !== undefined && !isSprintLoading) {
    optionList.data.map((val) => {
      options.push({
        value: val.project_sprint_id,
        label: val.name
      });
      return true;
    });
  }
  // Task Priority List
  const { data: taskPriorityList, isLoading: isTaskPriorityLoading } = useGetTaskPriorities();
  let options2 = [];
  if (taskPriorityList !== undefined && !isTaskPriorityLoading) {
    Object.entries(taskPriorityList).map(([value, label]) => {
      options2.push({ value, label });
      return true;
    });
  }
  // Get Employee List
  const { data: employeeList, isLoading: isUserLoading } = useGetAssignedProjectEmployeeList(id);
  const options1 = [];
  if (employeeList !== undefined && !isUserLoading) {
    employeeList.data.employee_list.map((val) => {
      options1.push({
        value: val.user_id,
        label: val.first_name + ' ' + val.last_name
      });
      return true;
    });
  }
  // Task Status Type List
  const { data: taskStatusTypeList, isLoading: isTaskStatusTypeLoading } = useGetTaskStatusTypes(id);
  const taskStatusTypeOptions = [];
  if (taskStatusTypeList !== undefined && !isTaskStatusTypeLoading) {
    taskStatusTypeList.data.map((val) => {
      taskStatusTypeOptions.push({
        value: val.task_status_type_id,
        label: val.status_name
      });
      return true;
    });
  }
  // Add Task API
  const { mutate: doAddTask, isLoading } = useAddTask((response) => {
    toast.success(response.message);
    socket.emit('task-updated',response?.data);
    formik.resetForm();
    navigate(`/projects/view/${id}/task`);
  }, (error) => {
    toast.error(error.message);
  });
  // Validation And Submit
  const formik = useFormik({
    initialValues: {
      project_id: id,
      sprint_id: '',
      task_status_type_id: '',
      name: '',
      original_estimate_time: '0.00',
      description: '',
      priority: '',
      assignee_ids: []
    },
    validationSchema,
    onSubmit: (values) => {
      doAddTask(values);
    }
  });
  // Cancel Form
  const handleCancel = () => {
    if (formik.dirty && formik.dirty !== undefined) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="alert-box">
              <FontAwesomeIcon className="alert-close" icon={faClose} onClick={() => { onClose(); }} />
              <div className="alert-popup">
                <h2 dangerouslySetInnerHTML={{ __html: t('page.reset_alert_popup_message') }}></h2>
                <Button className="table-delete-button" onClick={() => { onClose(); navigate(`/projects/view/${id}/task`); }}>
                  {t('page.alert_popup_yes_button')}
                </Button>
                <Button className="table-primary-button" onClick={onClose}>
                  {t('page.alert_popup_no_button')}
                </Button>
              </div>
            </div>
          );
        },
      });
    } else {
      navigate(`/projects/view/${id}/task`);
    }
  };
  return (
    <>
     <CommonSEOTitle
        title={t('page.project_task_add_title')}
      />
      <h1>{t('page.view_project_label')}</h1>
      <ProjectNavigation t={t} id={id} activeLabel='Tasks' />
      <Card className="inner-box">
        <h1 className="page-heading-center">{t('page.project_task_add_title')}</h1>
        <div className="edit-profile-form">
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg={6} xs={6}>
                <Form.Group>
                  <Form.Label className="field-label field-label-top">
                    {t('page.task_name_label')}
                  </Form.Label>
                  <Form.Control
                    className={
                      ' ' +
                      (formik.touched.name && formik.errors.name
                        ? 'form-field-error'
                        : formik.touched.name && !formik.errors.name
                          ? 'form-field-success'
                          : '')
                    }
                    type="text"
                    name="name"
                    placeholder={t('page.task_name_placeholder')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                  />
                  <div className="form-field-error-text">
                    {formik.touched.name && formik.errors.name ? (
                      <div>{t(formik.errors.name)}</div>
                    ) : null}
                  </div>
                </Form.Group>
              </Col>
              <Col lg={6} xs={6}>
                <Form.Group>
                  <Form.Label className="field-label field-label-top">
                    {t('page.task_sprint_label')}
                  </Form.Label>
                  <Select
                    placeholder={t('page.task_sprint_placeholder')}
                    options={options}
                    className={
                      ' ' +
                      (formik.touched.sprint_id && formik.errors.sprint_id
                        ? 'form-select-error'
                        : formik.touched.sprint_id && !formik.errors.sprint_id
                          ? 'form-select-success'
                          : '')
                    }
                    value={defaultValue(options, formik.values.sprint_id)}
                    onChange={(selectedOption) => {
                      formik.setFieldValue('sprint_id', selectedOption.value);
                    }}
                  />
                  <div className="form-field-error-text">
                    {formik.touched.sprint_id && formik.errors.sprint_id ? (
                      <div>{t(formik.errors.sprint_id)}</div>
                    ) : null}
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={4} xs={4}>
                <Form.Group>
                  <Form.Label className="field-label field-label-top">
                    {t('page.task_original_estimate_time_label')}
                  </Form.Label>
                  <Form.Control
                    className={
                      ' ' +
                      (formik.touched.original_estimate_time && formik.errors.original_estimate_time
                        ? 'form-field-error'
                        : formik.touched.original_estimate_time && !formik.errors.original_estimate_time
                          ? 'form-field-success'
                          : '')
                    }
                    name="original_estimate_time"
                    placeholder={t('page.task_original_estimate_time_placeholder')}
                    onBlur={formik.handleBlur}
                    value={formik.values.original_estimate_time}
                    onChange={(event)=>{
                      let value = event.target.value;
                      value = (value.length > 5) ? value.slice(0, 5) : value;
                      value = (value > 99) ? 99 : value;
                      formik.setFieldValue("original_estimate_time",value);
                    }}
                    type="number"
                    step={0.5}
                  />
                  <div className="form-field-error-text">
                    {formik.touched.original_estimate_time && formik.errors.original_estimate_time ? (
                      <div>{t(formik.errors.original_estimate_time)}</div>
                    ) : null}
                  </div>
                </Form.Group>
              </Col>
              <Col lg={4} xs={4}>
                <Form.Group>
                  <Form.Label className="field-label field-label-top">
                    {t('page.task_priority_label')}
                  </Form.Label>
                  <Select
                    placeholder={t('page.task_priority_placeholder')}
                    options={options2}
                    className={
                      ' ' +
                      (formik.touched.priority && formik.errors.priority
                        ? 'form-select-error'
                        : formik.touched.priority && !formik.errors.priority
                          ? 'form-select-success'
                          : '')
                    }
                    value={defaultValue(options2, formik.values.priority)}
                    onChange={(selectedOption) => {
                      formik.setFieldValue('priority', selectedOption.value);
                    }}
                  />
                  <div className="form-field-error-text">
                    {formik.touched.priority && formik.errors.priority ? (
                      <div>{t(formik.errors.priority)}</div>
                    ) : null}
                  </div>
                </Form.Group>
              </Col>
              <Col lg={4} xs={4}>
                <Form.Group>
                  <Form.Label className="field-label field-label-top">
                    {t('page.task_status_type_label')}
                  </Form.Label>
                  <Select
                    placeholder={t('page.task_status_type_placeholder')}
                    options={taskStatusTypeOptions}
                    className={
                      ' ' +
                      (formik.touched.task_status_type_id && formik.errors.task_status_type_id
                        ? 'form-select-error'
                        : formik.touched.task_status_type_id && !formik.errors.task_status_type_id
                          ? 'form-select-success'
                          : '')
                    }
                    value={defaultValue(taskStatusTypeOptions, formik.values.task_status_type_id)}
                    onChange={(selectedOption) => {
                      formik.setFieldValue('task_status_type_id', selectedOption.value);
                    }}
                  />
                  <div className="form-field-error-text">
                    {formik.touched.task_status_type_id && formik.errors.task_status_type_id ? (
                      <div>{t(formik.errors.task_status_type_id)}</div>
                    ) : null}
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={12} xs={12}>
                <Form.Group>
                  <Form.Label className="field-label field-label-top">
                    {t('page.task_assignee_label')}
                  </Form.Label>
                  <Select
                    placeholder={t('page.select_assignee_placeholder')}
                    options={options1.sort((a, b) => a.label.localeCompare(b.label, 'en', { numeric: true }))}
                    className={
                      formik.touched.assignee_ids && formik.errors.assignee_ids
                        ? 'form-select-error'
                        : formik.touched.assignee_ids && !formik.errors.assignee_ids
                          ? 'form-select-success'
                          : ''
                    }
                    onChange={(selectedOptions) => {
                      if (selectedOptions != null) {
                        formik.setFieldValue('assignee_ids', [selectedOptions]);
                      } else {
                        formik.setFieldValue('assignee_ids', []);
                      }
                    }}

                  />
                  <div className="form-field-error-text">
                    {formik.touched.assignee_ids && formik.errors.assignee_ids ? (
                      <div>{t(formik.errors.assignee_ids)}</div>
                    ) : null}
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Form.Group>
                  <Form.Label className="field-label field-label-top">
                    {t('page.task_description_label')}
                  </Form.Label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={descriptionD ? descriptionD : ''}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setDescriptionD(data);
                    }}
                    onBlur={(event, editor) => {
                      const data = editor.getData();
                      formik.setFieldValue('description', data);
                    }}
                    onFocus={(event, editor) => {
                    }}
                  />
                  <div className="form-field-error-text">
                    {formik.touched.description && formik.errors.description ? (
                      <div>{t(formik.errors.description)}</div>
                    ) : null}
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <div className="primary-button">
              <span className="link-center" onClick={handleCancel}>
                {t('page.cancel_button_text')}
              </span>
              <TNButton
                type="submit"
                loading={isLoading}
                isdirtyform={formik.dirty && formik.dirty !== undefined ? 1 : 0}>
                {t('page.save_button_text')}
              </TNButton>
            </div>
          </Form>
        </div>
      </Card>
    </>
  );
};
AddTaskPage.propTypes = {
  t: PropTypes.func,
};
export default AddTaskPage;