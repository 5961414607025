import client from '@libs/HttpClient';
class TeamMemberService {
  //TeamMember Master
  static listTeamMember(request) {
    const projectId = request.id;
    const page_no = request.page_no;
    const searchText = request.searchText;
    const memberType = request.memberType;
    return client.get(
      `/projects/view/${projectId}/team?search=${searchText}&page=${page_no}&type=${memberType}`,
      request
    );
  }
  static deleteTeamMember(projectId, id) {
    console.log(projectId, id);
    return client.delete(`/projects/view/${projectId}/team/${id}`);
  }
}
export { TeamMemberService };