import { useQuery, useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { settingData } from '../admin/store/features/settingSlice';
import { SettingService } from '@api/';
import { onDefaultError } from '../helpers/commonHelpers';
// Setting Data
const useGetSettingData = (onSuccess, onError) => {
  const getSettingData = useSelector(settingData);
  return useQuery(
    'setting_data',
    () => {
      return SettingService.getSettingData();
    },
    {
      onSuccess,
      onError,
      enabled: getSettingData.setting_get
    }
  );
};
// All Settings
const useGetSettingDataAlways = (onSuccess, onError) => {
  return useQuery(
    'setting_data_all',
    () => {
      return SettingService.getSettingData();
    },
    {
      onSuccess,
      onError
    }
  );
};
// All Settings Data
const useGetAllSettingData = (onSuccess, onError) => {
  return useQuery(
    'all_setting_data',
    () => {
      return SettingService.getAllSettingData();
    },
    {
      onSuccess,
      onError
    }
  );
};
// Update Setting Data
const useStoreSettingData = (onSuccess, onError = onDefaultError) => {
  return useMutation(SettingService.storeSettingData, {
    onSuccess,
    onError
  });
};
export { useGetSettingData, useStoreSettingData, useGetSettingDataAlways,useGetAllSettingData };