import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
const ListRolesPage = React.lazy(() => import('./ListRolesPage'));
const AddRolePage = React.lazy(() => import('./AddRolePage'));
const EditRolePage = React.lazy(() => import('./EditRolePageNew'));
const RolePermissionPage = React.lazy(() => import('../Permission/AssignPermission'));
const RoleRoutes = ({ t }) => {
    return (
        <Routes>
            <Route index exact path="/list" element={<ListRolesPage t={t} />} />
            <Route exact path="/add" element={<AddRolePage t={t} />} />
            <Route exact path="/edit/:role_id" element={<EditRolePage t={t} />} />
            <Route exact path="/permission/:role_id" element={<RolePermissionPage t={t} />} />
            <Route path="*" element={<Navigate replace to="/404" />} />
        </Routes>
    )
}
RoleRoutes.propTypes = {
    t: PropTypes.func
};
export default RoleRoutes;