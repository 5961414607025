const measureDistance =  (origins, destination) => {
return new Promise((resolve, reject) => {
        const service = new window.google.maps.DistanceMatrixService();
        const originAddress = `${origins.address1}, ${origins.address2}, ${origins.city}, ${origins.state}`;
        const destinationAddress = `${destination.address1}, ${destination.address2}, ${destination.city}, ${destination.state}`;

        const matrixOptions = {
            origins: [originAddress],
            destinations: [destinationAddress],
            travelMode: 'DRIVING',
            unitSystem: window.google.maps.UnitSystem.IMPERIAL
        };

        // Call Distance Matrix service
        service.getDistanceMatrix(matrixOptions, (response, status) => {

            if (status === "OK") {
                const distance = response.rows[0].elements[0].distance;

                resolve(distance);
            } else {
                reject(new Error("Error with distance matrix"));
            }
        });
    });

};

export { measureDistance };
