import * as Yup from 'yup';
import PhoneNumber from 'libphonenumber-js';
Yup.addMethod(Yup.string, 'phonecheck', function (errorMessage) {
  return this.test(`test-phone`, errorMessage, function (value) {
    const { country_code, phone_code } = this.parent;
    let isValidRequest = false;
    if(phone_code && value){
      const phoneNumber = "+"+phone_code+"-"+value;
      if(phoneNumber){
        const number = PhoneNumber(phoneNumber,country_code.toString());
        const isValid = number?.isValid();
        if (isValid) {isValidRequest = true;}
      }
    }
    return isValidRequest;
  });
});
let validationSchema = Yup.object({
  first_name: Yup.string()
    .required('page.profile_first_name_required')
    .matches(/^[aA-zZ\s]+$/, 'page.add_edit_profile_first_name_alphabates_validation'),
  last_name: Yup.string()
    .required('page.profile_last_name_required')
    .matches(/^[aA-zZ\s]+$/, 'page.add_edit_profile_last_name_alphabates_validation'),
  email: Yup.string('page.profile_email_required')
    .email('page.login_email_validation_email')
    .required('page.profile_email_required'),
  phone: Yup.string()
    .required('page.profile_phone_required')
    .phonecheck('page.profile_mobile_number_match'),
  country_id: Yup.string(('page.profile_country_id_required')).required(
    ('page.profile_country_id_required')
  ),
  country_code: Yup.string(('page.profile_country_code_required')).required(
    ('page.profile_country_code_required')
  ),
  phone_code: Yup.string(('page.profile_country_phone_code_required')).required(
    ('page.profile_country_phone_code_required')
  ),
});
let emailValidationSchema = Yup.object({
  email: Yup.string('page.profile_email_required')
    .email('page.login_email_validation_email')
    .required('page.profile_email_required')
});
let validationSchemaVerify = Yup.object({
  otp: Yup.string()
    .required('page.profile_email_otp_required')
    .matches(/^[0-9\s]+$/, 'page.profile_email_otp_matches')
});
export { validationSchema, emailValidationSchema, validationSchemaVerify };