import { useMutation, useQuery } from 'react-query';
import { AttendanceService } from '@api/AttendanceService';
import { onDefaultError } from '../helpers/commonHelpers';
// Records
const useListAttendance = ([page_no, searchText, id], onSuccess, onError = onDefaultError) => {
  return useQuery(['attendance-list', page_no, searchText, id], AttendanceService.listAttendance, {
    onSuccess,
    keepPreviousData: true,
    onError
  });
};
// Create
const useAddAttendance = (onSuccess, onError = onDefaultError) => {
  return useMutation(AttendanceService.addAttendance, {
    onSuccess,
    onError
  });
};
// User List
const useGetUserList = (onSuccess, onError = onDefaultError) => {
  return useQuery('attendance-user-list', AttendanceService.getAttendanceUsers, {
    onSuccess,
    onError
  });
};
// Attendance Type List
const useGetAttendanceTypeList = (onSuccess, onError = onDefaultError) => {
  return useQuery('attendance/type-list', AttendanceService.useGetAttendanceTypeList, {
    onSuccess,
    onError
  });
};
// Details
const useViewAttendance = (id, onSuccess, onError = onDefaultError) => {
  return useQuery('attendance-view', () => AttendanceService.viewAttendance(id), {
    onSuccess,
    onError
  });
};
// Update
const useUpdateAttendance = (onSuccess, onError = onDefaultError) => {
  return useMutation(AttendanceService.updateAttendance, {
    onSuccess,
    onError
  });
};
// Remove
const useDeleteAttendance = (onSuccess, onError = onDefaultError) => {
  return useMutation(AttendanceService.deleteAttendance, {
    onSuccess,
    onError
  });
};
// Reject
const useRejectAttendance = (onSuccess, onError = onDefaultError) => {
  return useMutation(AttendanceService.rejectAttendance, {
    onSuccess,
    onError
  });
};
// Approve
const useApproveAttendance = (onSuccess, onError = onDefaultError) => {
  return useMutation(AttendanceService.approveAttendance, {
    onSuccess,
    onError
  });
};
export {
  useListAttendance,
  useAddAttendance,
  useGetUserList,
  useGetAttendanceTypeList,
  useViewAttendance,
  useUpdateAttendance,
  useDeleteAttendance,
  useRejectAttendance,
  useApproveAttendance
};