import { useMutation, useQuery } from 'react-query';
import { DatabaseService } from '@api/DatabaseService';
import { onDefaultError } from '../helpers/commonHelpers';
//Database Master
// Get Records
const useListDatabase = (
  projectId,
  page_no,
  searchText,
  status,
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    ['database-list', projectId, page_no, searchText, status],
    () => DatabaseService.listDatabase({ projectId, page_no, searchText, status }),
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};
// Create
const useAddDatabase = (onSuccess, onError = onDefaultError) => {
  return useMutation(DatabaseService.addDatabase, {
    onSuccess,
    onError
  });
};
// Update
const useUpdateDatabase = (onSuccess, onError = onDefaultError) => {
  return useMutation(DatabaseService.updateDatabase, {
    onSuccess,
    onError
  })
}
// Details
const useViewDatabase = (projectId, id, onSuccess, onError = onDefaultError) => {
  return useQuery('database-view', () => DatabaseService.viewDatabase(projectId, id), {
    onSuccess,
    onError
  });
};
// Delete
const useDeleteDatabase = () => {
  const deleteDatabase = async (projectId, id) => {
    return await DatabaseService.deleteDatabase(projectId, id);
  };
  return { deleteDatabase };
};
export {
  useAddDatabase, 
  useUpdateDatabase, 
  useViewDatabase, 
  useDeleteDatabase, 
  useListDatabase,
};