import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
const ListAttendancePage = React.lazy(() => import('./ListAttendancePage'));
const AddAttendancePage = React.lazy(() => import('./AddAttendancePage'));
const EditAttendancePage = React.lazy(() => import('./EditAttendancePage'));
const ViewAttendancePage = React.lazy(() => import('./ViewAttendancePage'));
const AttendanceRoute = ({ t }) => {
  return (
    <Routes>
      <Route index exact path="/list" element={<ListAttendancePage t={t} />} />
      <Route exact path="/add" element={<AddAttendancePage t={t} />} />
      <Route exact path="/edit/:id" element={<EditAttendancePage t={t} />} />
      <Route exact path="/view/:id" element={<ViewAttendancePage t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
AttendanceRoute.propTypes = {
  t: PropTypes.func
};
export default AttendanceRoute;