import { useMutation, useQuery } from 'react-query';
import { UserService } from '@api/';
import { onDefaultError } from '../helpers/commonHelpers';
// Get User Profile Data
const useGetProfile = (onSuccess, onError = onDefaultError) => {
  return useQuery('get-profile', UserService.getProfile, {
    onSuccess,
    onError
  });
};
// Get Country List
const useGetCountryCodeList = (onSuccess, onError = onDefaultError) => {
  return useQuery('country-code-list', UserService.getCountryCodeList, {
    onSuccess,
    onError
  });
};
// Update User Profile
const useUpdateProfile = (onSuccess, onError = onDefaultError) => {
  return useMutation(UserService.updateProfile, {
    onSuccess,
    onError
  });
};
// Change User Password
const useChangePassword = (onSuccess, onError = onDefaultError) => {
  return useMutation(UserService.updatePassword, {
    onSuccess,
    onError
  });
};
// Verify Email ID For Update
const useUpdateEmailVerify = (onSuccess, onError = onDefaultError) => {
  return useMutation(UserService.updateEmailVerify, {
    onSuccess,
    onError
  });
};
// Update Email ID
const useUpdateEmail = (onSuccess, onError = onDefaultError) => {
  return useMutation(UserService.updateEmail, {
    onSuccess,
    onError
  });
};
export {
  useGetProfile,
  useUpdateProfile,
  useChangePassword,
  useGetCountryCodeList,
  useUpdateEmailVerify,
  useUpdateEmail
};