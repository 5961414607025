import client from '@libs/HttpClient';
class DBFieldTypeService {
  //DB Field Type Master
  static listDBFieldType(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const status = request.queryKey[3];
    const dbfieldtype_id = request.queryKey[4] || '';
    return client.get(
      `/dbfieldtype/?search=${searchText}&page=${page_no}&status=${status}&db_field_type_id=${dbfieldtype_id}`,
      request
    );
  }
  static addDBFieldType(request) {
    return client.post(`/dbfieldtype`, request);
  }
  static viewDBFieldType(params) {
    return client.get(`/dbfieldtype/${params}/edit`);
  }
  static updateDBFieldType(request) {
    return client.put(`/dbfieldtype/${request.id}`, request);
  }
  static deleteDBFieldType(request) {
    return client.delete(`/dbfieldtype/${request.id}`, request);
  }
  static statusChangeDBFieldType(request) {
    return client.patch(`/dbfieldtype/update-status/${request.id}`, request);
  }
}
export { DBFieldTypeService };