import client from '@libs/HttpClient';
class SettingService {
  static getSettingData() {
    return client.get('/setting/get');
  }
  static storeSettingData(request) {
    return client.post('/setting/store', request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
  static getAllSettingData() {
    return client.get('/setting/all-data');
  }
}
export { SettingService };