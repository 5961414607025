import client from '@libs/HttpClient';

class TableColumnService {

  //Table Column Master

  static listTableColumn(request) {
    const projectId = request.projectId ?? "";
    const databaseId = request.databaseId ?? "";
    const tableId = request.expandedRowId ?? "";
    const page_no = request.page_no ?? "";
    const searchText = request.searchText ?? "";
    const status = request.status ?? "";
    const key = request.key ?? "";
    const dateRange = request.dateRange ?? "";
    const dbFieldName = request.dbFieldName ?? "";
    return client.get(
      `/projects/view/${projectId}/database/${databaseId}/table/${tableId}/columns?search=${searchText}&page=${page_no}&status=${status}&key=${key}&date_range=${dateRange}&dbFieldName=${dbFieldName}`,
      request
    );
  }

  static addTableColumn(projectId, databaseId, tableId, requestData) {
    return client.post(`/projects/view/${projectId}/database/${databaseId}/table/${tableId}/columns`, requestData);
  }

  static viewTableColumn(projectId, databaseId, tableId, id) {
    return client.get(`/projects/view/${projectId}/database/${databaseId}/table/${tableId}/columns/${id}/edit`);
  }

  static getDBFieldType(request) {

    return client.get(`/get/db-field-type-list`, request);
  }
  static getDBFieldTypeList(request) {

    return client.get(`/get/db-field-type-list-filter`, request);
  }

  static async getTable(databaseId) {

    return client.get(`/get/table-list/${databaseId}`);
  }

  static async getTableList(databaseId) {
    return client.get(`/get/table-list-filter/${databaseId}`);
  }

  static async getTableColumn(tableId) {
    return client.get(`/get/${tableId}/table-column-list`);
  }

  static async getTableColumnList(tableId) {
    return client.get(`/get/${tableId}/table-column-list-filter`);
  }

  static updateTableColumn(request) {
    const id = request.id;
    const projectId = request.projectId;
    const databaseId = request.databaseId;
    const tableId = request.tableId;
    return client.put(`/projects/view/${projectId}/database/${databaseId}/table/${tableId}/columns/${id}`, request);
  }

  static deleteTableColumn(request) {
    const id = request.id;
    const projectId = request.projectId;
    const databaseId = request.databaseId;
    const tableId = request.tableId;
    return client.delete(`/projects/view/${projectId}/database/${databaseId}/table/${tableId}/columns/${id}`);
  }

  static reviewDatabaseTableColumn(request) {
    const projectId = request.projectId;
    const databaseId = request.databaseId;
    const tableId = request.tableId;
    const id = request.id
    return client.post(`/projects/view/${projectId}/database/${databaseId}/tables/${tableId}/columns/${id}`, request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }

}
export { TableColumnService };
