import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
const ListDatabasePage = React.lazy(() => import('./ListDatabasePage'));
const AddDatabasePage = React.lazy(() => import('./AddDatabasePage'));
const EditDatabasePage = React.lazy(() => import('./EditDatabasePage'));
const DatabaseRoutes = ({ t }) => {
  return [
    <Route key="database_list" exact path="/view/:id/database" element={<ListDatabasePage t={t} />} />,
    <Route key="database_add" exact path="/view/:projectId/database/add" element={<AddDatabasePage t={t} />} />,
    <Route key="database_edit" exact path="/view/:projectId/database/:id/edit" element={<EditDatabasePage t={t} />} />
  ];
};
DatabaseRoutes.propTypes = {
  t: PropTypes.func
};
export default DatabaseRoutes;