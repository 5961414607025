import React from 'react';
import { useTable } from 'react-table';
import { Table } from 'react-bootstrap'; // Adjust import based on your table component

const ChildTable = ({ columns, data,t }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data,t });

  return (
    <Table {...getTableProps()} className='child-table'>
      <thead>
        {headerGroups.map(headerGroup => (

          <tr {...headerGroup.getHeaderGroupProps()} >
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()} className={rows.length === 0 ? 'd-none' : ''}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          );
        })}
      </tbody>
      <tbody {...getTableBodyProps()} className={rows.length === 0 ? '' : 'd-none'}>
        <tr>
          <td colSpan={columns.length}>{t('page.no_data_found_table')}</td>
        </tr>
      </tbody>
    </Table>
  );
};

export default ChildTable;
