// React Dependencies
import React from 'react';
// Common Dependencies
import { Routes, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
// Components
import DatabaseRoutes from '../Database/DatabaseRoute';
import DatabaseTableRoutes from '../DatabaseTable/DatabaseTableRoute';
import TableColumnRoutes from '../TableColumn/TableColumnRoute';
import TeamMemberRoutes from '../Teams/TeamMemberRoute';
import TaskStatusTypeRoutes from '../TaskStatusType/TaskStatusTypeRoute';
import SprintRoutes from '../Sprints/SprintRoute';
import TaskRoutes from '../Task/TaskRoute';
// Routes
const ListProjectPage = React.lazy(() => import('./ListProjectPage'));
const AddProjectPage = React.lazy(() => import('./AddProjectPage'));
const EditProjectPage = React.lazy(() => import('./EditProjectPage'));
const ViewProjectPage = React.lazy(() => import('./ViewProjectPage'));
const ProjectRoute = ({ t }) => {
  return (
    <Routes key={"routes"}>
        <Route key={"project_list"} index exact path="/list" element={<ListProjectPage t={t} />} />
        <Route key={"project_add"} exact path="/add" element={<AddProjectPage t={t} />} />
        <Route key={"project_edit"} exact path="/edit/:id" element={<EditProjectPage t={t} />} />
        <Route key={"project_view"} exact path="/view/:id" element={<ViewProjectPage t={t} />} />
        {/* Include DatabaseRoutes */}
        {DatabaseRoutes({ t })}
        {/* Include DatabaseTableRoutes */}
        {DatabaseTableRoutes({ t })} 
        {/* Include TableColumnRoutes */}
        {TableColumnRoutes({ t })} 
        {TeamMemberRoutes({ t })}
        {TaskStatusTypeRoutes({ t })}
        {SprintRoutes({ t })}
        {TaskRoutes({ t })}
    </Routes>
  );
};
ProjectRoute.propTypes = {
  t: PropTypes.func
};
export default ProjectRoute;