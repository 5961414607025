// React Dependencies
import React from 'react';
import PropTypes from 'prop-types';
const DashboardPage = ({ t }) => {
  return <h1> {t('page.dashboard_page_label')}</h1>;
};
DashboardPage.propTypes = {
  t: PropTypes.func,
  isdirtyform: PropTypes.any
};
export { DashboardPage };