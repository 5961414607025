import { useMutation, useQuery } from 'react-query';
import { DatabaseTableService } from '@api/DatabaseTableService';
import { toast } from 'react-toastify';
import { onDefaultError } from '../helpers/commonHelpers';
//Database Table Master
const useListDatabaseTable = (
  params,
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    ['database-table-list', [params]],
    () => DatabaseTableService.listDatabaseTable(params),
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};
const useAddDatabaseTable = (projectId, databaseId, navigate) => { // Accept navigate as an argument
  const { mutate, isLoading } = useMutation(
    (requestData) => DatabaseTableService.addDatabaseTable(projectId, databaseId, requestData),
    {
      onSuccess: (response) => {
        toast.success(response.message);
        navigate(`/projects/view/${projectId}/database/${databaseId}/tables`); // Use navigate here
      },
      onError: onDefaultError
    }
  );
  const doAddDatabaseTable = async (values) => {
    await mutate(values);
  };
  return { doAddDatabaseTable, isLoading };
};
const useUpdateDatabaseTable = (onSuccess, onError = onDefaultError) => {
  return useMutation(DatabaseTableService.updateDatabaseTable, {
    onSuccess,
    onError
  })
}
const useViewDatabaseTable = (projectId, databaseId, id, onSuccess, onError = onDefaultError) => {
  return useQuery('database-table-view', () => DatabaseTableService.viewDatabaseTable(projectId, databaseId, id), {
    onSuccess,
    onError
  });
};
const useDeleteDatabaseTable = () => {
  const deleteDatabaseTable = async (projectId, databaseId, id) => {
    return await DatabaseTableService.deleteDatabaseTable(projectId, databaseId, id);
  };
  return { deleteDatabaseTable };
};
const useReviewDatabase = (onSuccess, onError = onDefaultError) => {
  return useMutation(DatabaseTableService.reviewDatabase, {
    onSuccess,
    onError
  })
}
export {
  useAddDatabaseTable,
  useUpdateDatabaseTable,
  useViewDatabaseTable,
  useDeleteDatabaseTable,
  useListDatabaseTable,
  useReviewDatabase
};