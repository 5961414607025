// React Dependencies
import { React, useEffect } from 'react';
// Common Dependencies
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// Components
import { Container, Form, Button } from 'react-bootstrap';
// Validation
import { useFormik } from 'formik';
import validationSchema from './OtpVerificationValidation';
import { confirmAlert } from 'react-confirm-alert';
// Alert
import { toast } from 'react-toastify';
// API Call
import { useResendOtp, useOTPVerify } from '@hooks';
// Page Components
import { PublicLayout, LogoUrl } from '@adminlayout';
import { TNButton } from '@common/components';
// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
// Loaders
import { showLoader, hideLoader } from '../../../store/features/loaderSlice';
const OtpVerificationPage = ({ t }) => {
  // Navigation And Dispatch
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Resent OTP
  const { mutate: doResendOtp } = useResendOtp(
    (response) => {
      dispatch(hideLoader());
      toast.success(response.message);
    },
    (error) => {
      dispatch(hideLoader());
      toast.error(error.message);
    }
  );
  // Navigate To Login Page
  const handleLoginButton = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="alert-box">
            <FontAwesomeIcon className="alert-close" icon={faClose} onClick={() => { onClose(); }} />
            <div className="alert-popup">
              <h2 dangerouslySetInnerHTML={{ __html: t('page.login_reset_alert_popup_message') }}></h2>
              <Button className="table-delete-button" onClick={() => { onClose(); navigate(`/login/`);}}>
                {t('page.alert_popup_yes_button')}
              </Button>
              <Button className="table-primary-button" onClick={onClose}>
                {t('page.alert_popup_no_button')}
              </Button>
            </div>
          </div>
        );
      }
    });
  };
  // OTP Validate And Submit
  const { mutate: doOtpVerify, isLoading: isLoadingOtpVerify, error: addOVList, isError: addOVError } = useOTPVerify(
    (response) => {
      toast.success(response.message);
      localStorage.verify_otp_token = response.data.user_id;
      localStorage.removeItem('forgot_token');
      navigate('/reset-password');
    },
    (error) => {
      window.scrollTo(0, 0);
      toast.error(error.message);
    }
  );
  // Add Class To The Body And Set Local Storage
  useEffect(() => {
    document.body.classList.add('bg-box');
    if (
      localStorage.isAdmin === undefined ||
      localStorage.isAdmin === '' ||
      localStorage.forgot_token === undefined ||
      localStorage.forgot_token === ''
    ) {
      navigate('/login');
    }
  });
  // Resend OTP
  const handleResend = () => {
    const values = {};
    values.user_id = localStorage.forgot_token;
    dispatch(showLoader());
    doResendOtp(values);
  };
  // Validate And Verify OTP
  const formik = useFormik({
    initialValues: {
      otp: ''
    },
    validationSchema,
    onSubmit: (values) => {
      values.user_id = localStorage.forgot_token;
      doOtpVerify(values);
    }
  });
  return (
    <PublicLayout>
      <div className="auth-pages">
        <Container>
          <div className="background-box">
            <div>
              <div className="brand-logo"><LogoUrl /></div>
              <div className="admin-heading">
                <h1 className="page-heading-center">{t('page.otp_verification_header_text')}</h1>
                <div className="page-sub-heading">
                  <span>
                    <div dangerouslySetInnerHTML={{__html: t('page.otp_verification_sub_header_text')}}></div>
                  </span>
                </div>
              </div>
              <div>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
                    <Form.Label className="field-label">{t('page.otp_verification_otp_label')}</Form.Label>
                    <Form.Control
                      className={
                        'form-field ' +
                        (formik.touched.otp && formik.errors.otp
                          ? 'form-field-error'
                          : formik.touched.otp && !formik.errors.otp
                          ? 'form-field-success'
                          : '')
                      }
                      type="text"
                      name="otp"
                      maxLength="4"
                      onKeyUp={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      placeholder={t('page.otp_verification_otp_placeholder')}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.otp}
                    />
                    {formik.touched.otp && formik.errors.otp ? (
                      <div className="form-field-error-text">{t(formik.errors.otp)}</div>
                    ) : null}
                  </Form.Group>
                  <div className="link-right">
                    <span style={{ color: '#424242', fontWeight: '500' }}>
                      {t('page.otp_verification_resend_link_header_text')}
                    </span>
                    <span className="resend-otp" onClick={handleResend}>
                      {t('page.otp_verification_resend_link_text')}
                    </span>
                  </div>
                  <div className="primary-button">
                    <span className="link-center" onClick={handleLoginButton}>
                      {t('page.otp_login_link_text')}
                    </span>
                    <TNButton
                      loading={isLoadingOtpVerify}
                      type="submit"
                      isdirtyform={formik.dirty && formik.dirty !== undefined ? 1 : 0}>
                      {t('page.otp_verification_submit_button_text')}
                    </TNButton>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </PublicLayout>
  );
};
OtpVerificationPage.propTypes = {
  t: PropTypes.func,
  isdirtyform: PropTypes.any
};
export { OtpVerificationPage };