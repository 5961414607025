import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
const ProjectNavigation = (props) => {
    const { t, id, activeLabel } = props;
    const navigateArray = [
        {
            label : t('page.project_details_tab'),
            link : `/projects/view/${id}`,
            identity : 'Project Details'
        },
        {
            label : t('page.project_database_tab'),
            link : `/projects/view/${id}/database`,
            identity : 'Database'
        },
        {
            label : t('page.project_team_member_tab'),
            link : `/projects/view/${id}/team`,
            identity : 'Team Member'
        },
        {
            label : t('page.project_task_status_type_tab'),
            link : `/projects/view/${id}/task-status-type`,
            identity : 'Task Status Type'
        },
        {
            label : t('page.project_sprint_tab'),
            link : `/projects/view/${id}/sprint`,
            identity : 'Sprint'
        },
        {
            label : t('page.project_task_tab'),
            link : `/projects/view/${id}/task`,
            identity : 'Tasks'
        }
    ];
    return (
        <Nav className="tab-navigation">
            {navigateArray.map((navigateObject,index) => (
                <Nav.Item key={index}>
                    <NavLink className={activeLabel !== navigateObject.identity ? "inactiveLink" : ""} to={navigateObject.link}>{navigateObject.label}</NavLink>
                </Nav.Item>
            ))}
        </Nav>
    )
}
ProjectNavigation.propTypes = {
    t: PropTypes.func,
    id : PropTypes.string,
    activeLabel : PropTypes.string
}
export { ProjectNavigation };