import axios from 'axios';
import { createBrowserHistory } from 'history';
const myHistory = createBrowserHistory({ window });
const client = axios.create({
  baseURL: process.env.MIX_API_URL,
  timeout: 100000,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin' : '*',
  }
});
client.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
client.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {

    if (!error.response) {
      throw new Error('Error Connection');
    }
    if (error.response.status === 401) {
      window.localStorage.clear();
      // let authType = client.defaults.headers.common.AuthType ?? 'admin';
      // myHistory.replace(`/${authType}/login`); // Usage example.
      myHistory.replace(`/login`); 
      window.location.reload();
    }
    return Promise.reject(error.response.data);
  }
);
export default client;
export const setAuthToken = (token) => {
  client.defaults.headers.common['Authorization'] = '';
  delete client.defaults.headers.common['Authorization'];
  if (token) {
    client.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
};
export const setAuthType = (type) => {
  client.defaults.headers.common['AuthType'] = '';
  delete client.defaults.headers.common['AuthType'];
  if (type) {
    client.defaults.headers.common['AuthType'] = type;
  }
};