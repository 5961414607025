import * as Yup from 'yup';
let validationSchema = Yup.object({
  seo_title: Yup.string('page.general_setting_seo_title_required').required(
    'page.general_setting_seo_title_required'
  ),
  seo_description: Yup.string('page.general_setting_seo_description_required').required(
    'page.general_setting_seo_description_required'
  ),
  common_dirty_email_data: Yup.string('page.common_setting_dirty_email_required')
    .required('page.common_setting_dirty_email_required')
    .email('page.login_email_validation_email'),
  email_logo: Yup.mixed('page.general_setting_email_logo_required').required(
    'page.general_setting_email_logo_required'
  ),
  home_logo: Yup.mixed('page.general_setting_home_logo_required').required(
    'page.general_setting_home_logo_required'
  ),
  login_logo: Yup.mixed('page.general_setting_login_logo_required').required(
    'page.general_setting_login_logo_required'
  ),
  favicon_icon: Yup.mixed('page.general_setting_favicon_icon_required').required(
    'page.general_setting_favicon_icon_required'
  )
});
export default validationSchema;