// React Dependencies
import React, { useEffect, useRef, useState } from 'react';
// Components
// Bootstrap
import { Nav, Form, Row, Col, Card, InputGroup, FormControl, Modal, Button } from 'react-bootstrap';
// React Select
import Select from 'react-select';
// Custom Button
import { TNButton } from '@common/components/TNButton';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// Router DOM
import { NavLink, useNavigate } from 'react-router-dom';
// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faEdit, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
// Validation
import { useFormik } from 'formik';
import {
  validationSchema,
  emailValidationSchema,
  validationSchemaVerify
} from './EditProfileValidation';
// Alert
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
// API Functions
import {
  useGetProfile,
  useUpdateProfile,
  useGetCountryCodeList,
  useUpdateEmailVerify,
  useUpdateEmail,
  useResendOtp
} from '@hooks';
import { loggedUser, updateUserData } from './../../../store/features/authSlice';
// Loader
import { showLoader, hideLoader } from './../../../store/features/loaderSlice';
// Assets
import '@assets/scss/page/_editprofile.scss';
import CommonSEOTitle from '../../../../helpers/CommonSEOTitle';
const EditProfilePage = ({ t }) => {
  // User Profile Image
  // Default Image URL
  const defaultImageURL = 'assests/images/profile/default.png';
  const profilePic = useRef();
  const [userProfile, setUserProfile] = useState(defaultImageURL);
  // Navigate
  const navigate = useNavigate();
  // User auth check
  const checkLoggedInUser = useSelector(loggedUser);
  // Dispatch
  const dispatch = useDispatch();
  // States
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedCountryCode, setSelectedCountryCode] = useState('IN');
  const [selectedCountryPhoneCode, setSelectedCountryPhoneCode] = useState('91');
  const [countryList, setCountryList] = useState([]);
  const [show, setShow] = useState(false);
  const [showVerify, setShowVerify] = useState(false);
  const [verifyToken, setVerifyToken] = useState('');
  const [userEncId, setUserEncId] = useState('');
  // Modal Close 
  const handleClose = () => {
    setShow(false);
    formikEmail.resetForm();
  };
  // Formik value set
  const { refetch, isLoading } = useGetProfile(({ data: profile }) => {
    if (profile) {
      formik.values.first_name = profile.first_name;
      formik.values.last_name = profile.last_name;
      formik.values.email = profile.email;
      formik.values.phone = profile.phone;
      formik.values.country_id = profile.country_id;
      formik.values.profile_image = profile.profile_image;
      formik.values.country_code = selectedCountryCode;
      formik.values.phone_code = selectedCountryPhoneCode;
      setSelectedCountry(profile.country_id);
    }
  },
    (error) => {
      if (error?.data?.redirect == true) {
        toast.error(error.message);
        navigate('/dashboard');
      }else{
        toast.error(error.message);
      }
    });
  // Country Code list initialization
  const { data: countryCodeList } = useGetCountryCodeList();
  useEffect(() => {
    if (countryCodeList) {
      let options = [];
      countryCodeList.data.forEach(value => {
        let object = {
          value: value.country_id,
          label: value.phone_code + ' (' + value.name + ')',
          phone_code: value.phone_code,
          country_code : value.code
        }
        options.push(object);
      });
      setCountryList(options);
    }
    if(formik?.values?.country_id && countryCodeList.data && countryCodeList.data?.length > 0){
      let countryCode = countryCodeList.data.filter((row)=>{ return row.country_id == formik?.values?.country_id});
      if(countryCode && countryCode?.length > 0){
        countryCode = countryCode[0];
        setSelectedCountryCode(countryCode?.code);
        setSelectedCountryPhoneCode(countryCode?.phone_code);
      }
    }
  }, [countryCodeList,formik?.values?.country_id])
  // Click Events
  // Update User
  const { mutate: doUserUpdateProfile} = useUpdateProfile(
    (response) => {
      toast.success(response.message);
      const dataStore = {
        userData: response.data
      };
      dispatch(updateUserData(dataStore));
    },
    (error) => {
      window.scrollTo(0, 0);
      toast.error(error.message);
    }
  );
  // Close Modal
  const handleCloseModal = (type) => {
    if (formik.dirty && formik.dirty !== undefined) {
      const modalAlertMessage = type == 'image_cancel' ? t('page.remove_profile_image') : t('page.reset_alert_popup_message');
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="alert-box">
              <FontAwesomeIcon className="alert-close" icon={faClose} onClick={() => { onClose(); }} />
              <div className="alert-popup">
                <h5 dangerouslySetInnerHTML={{ __html: modalAlertMessage }}></h5>
                <Button className="table-delete-button" onClick={() => { onClose();closeModalEvents(type); }}>
                  {t('page.alert_popup_yes_button')}
                </Button>
                <Button className="table-primary-button" onClick={onClose}>
                  {t('page.alert_popup_no_button')}
                </Button>
              </div>
            </div>
          );
        }
      });
    } else {
      closeModalEvents(type);
    }
  };
  // Handle Close Events
  const closeModalEvents = (type) => {
    if(type == 'cancel'){
      navigate(`/dashboard/`);
    } else if(type == 'email_cancel'){
      setShow(false);
      formikEmail.resetForm();
    } else if(type == 'otp_cancel'){
      setShowVerify(false);
      setVerifyToken(null);
      formikEmailVerify.resetForm();
    } else if(type == 'image_cancel'){
      formik.setFieldValue('profile_image', "");
      setUserProfile(defaultImageURL);
    }
  }
  // Form Submit After Validation
  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      profile_image: ''
    },
    validationSchema,
    onSubmit: async (values) => {
      if (profilePic?.current?.files[0] && userProfile !== defaultImageURL) {
        values.profile_image = profilePic?.current?.files[0] ?? '';
      }
      values.country_id = selectedCountry;
      doUserUpdateProfile(values);
    }
  });
  // Email Update
  // Update Email ID API Call
  const { mutate: doUserUpdateEmail, isLoading: isLoadingEmail } = useUpdateEmail(
    (response) => {
      toast.success(response.message);
      setShow(false);
      setShowVerify(true);
      setVerifyToken(response.data.email);
      setUserEncId(response.data.encrypted_user_id);
    },
    (error) => {
      toast.error(error.message);
    }
  );
  // Verify Email ID API Call
  const { mutate: doUserUpdateEmailVerify,isLoading: isLoadingEmailVerify } = useUpdateEmailVerify(
    (response) => {
      toast.success(response.message);
      setShow(false);
      setShowVerify(false);
      setVerifyToken(null);
      refetch();
    },
    (error) => {
      toast.error(error.message);
    }
  );
  // Email Modal Form Submit After Validation
  const formikEmail = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: emailValidationSchema,
    onSubmit: async (values) => {
      doUserUpdateEmail(values);
    }
  });
  // Resend OTP
  const handleResendOtp = () => {
    const values = {};
    values.user_id = userEncId;
    values.new_email = verifyToken;
    dispatch(showLoader());
    doResendOtp(values);
  };
  // Resend OTP API Call
  const { mutate: doResendOtp } = useResendOtp(
    (response) => {
      dispatch(hideLoader());
      toast.success(response.message);
    },
    (error) => {
      toast.success(error.message);
      dispatch(hideLoader());
    }
  );
  // Verify OTP API Call
  const formikEmailVerify = useFormik({
    initialValues: {
      otp: '',
      email: verifyToken
    },
    validationSchema: validationSchemaVerify,
    onSubmit: async (values) => {
      values.email = verifyToken;
      doUserUpdateEmailVerify(values);
      formikEmailVerify.resetForm();
    }
  });
  // Profile Image
  // Image Preview On Upload
  function imagePreview(e) {
    if (e.target.files && e.target.files.length > 0) {
      formik.setFieldValue('profile_image', e.target.files[0]);
      setUserProfile(URL.createObjectURL(e.target.files[0]));
    }
  }
  // Set Profile Image On Load
  useEffect(() => {
    setUserProfile(checkLoggedInUser.user.profile_image);
    let filename = "";
    if (checkLoggedInUser?.user?.profile_image) {
      filename = checkLoggedInUser?.user?.profile_image.split('/').pop()
      let currentImageURL = checkLoggedInUser?.user?.profile_image;
      if (currentImageURL.search(defaultImageURL) >= 0) {
        setUserProfile(defaultImageURL);
      }
    }
    formik.setFieldValue('profile_image', filename);
  }, [checkLoggedInUser.user.profile_image]);
  return (
    <>
    <CommonSEOTitle
      title={t('page.edit_profile')}
    />
      {/* Tab Navigation */}
      <Nav className="tab-navigation">
        <Nav.Item>
          <NavLink to="/edit-profile">{t('page.edit_profile')}</NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/change-password">{t('page.edit_change_password')}</NavLink>
        </Nav.Item>
      </Nav>
      {/* Edit Profile */}
      <Card className="inner-box">
        <h1 className="page-heading-center"> {t('page.edit_profile_text')}</h1>
        <div className="edit-profile-form">
          <Form onSubmit={formik.handleSubmit}>
            <div className="edit-profile-img ">
              <img src={userProfile} alt="profile_img" />
            </div>
            <div className="upload-profile">
              <label htmlFor="profile_image">{userProfile && userProfile !== defaultImageURL ? t('page.edit_profile_upload_photo') : <> {t('page.add_profile_upload_photo')}{" "}<FontAwesomeIcon className="alert-close" icon={faUpload} /> </>}</label>
              <input
                name="profile_image"
                id="profile_image"
                onChange={imagePreview}
                ref={profilePic}
                style={{ visibility: 'hidden', display: 'none' }}
                type={'file'}>
              </input>
              {userProfile !== defaultImageURL && (<FontAwesomeIcon className="alert-close remove-fa-button" icon={faTrash} onClick={() => { handleCloseModal('image_cancel'); }}/>)}
            </div>
            <Row>
              <Col lg={6} md={6} xs={12}>
                <Form.Group>
                  <Form.Label className="field-label field-label-top">{t('page.profile_first_name')}</Form.Label>
                  <Form.Control
                    className={
                      'form-field ' +
                      (formik.touched.first_name && formik.errors.first_name
                        ? 'form-field-error'
                        : formik.touched.first_name && !formik.errors.first_name
                          ? 'form-field-success'
                          : '')
                    }
                    type="text"
                    name="first_name"
                    placeholder={t('page.profile_first_name_placeholder')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.first_name}
                  />
                  <div className="form-field-error-text">
                    {formik.touched.first_name && formik.errors.first_name ? (
                      <div>{t(formik.errors.first_name)}</div>
                    ) : null}
                  </div>
                </Form.Group>
              </Col>
              <Col lg={6} md={6} xs={12}>
                <Form.Group>
                  <Form.Label className="field-label field-label-top">{t('page.profile_last_name')}</Form.Label>
                  <Form.Control
                    className={
                      'form-field ' +
                      (formik.touched.last_name && formik.errors.last_name
                        ? 'form-field-error'
                        : formik.touched.last_name && !formik.errors.last_name
                          ? 'form-field-success'
                          : '')
                    }
                    type="text"
                    name="last_name"
                    placeholder={t('page.profile_last_name_placeholder')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.last_name}
                  />
                  <div className="form-field-error-text">
                    {formik.touched.last_name && formik.errors.last_name ? (
                      <div>{t(formik.errors.last_name)}</div>
                    ) : null}
                  </div>
                </Form.Group>
              </Col>
              <Col lg={6} md={6} xs={12}>
                <Form.Group>
                  <Form.Label className="field-label field-label-top">{t('page.profile_email')}</Form.Label>
                  <InputGroup className="form-group-field epclass">
                    <FormControl
                      className={
                        '' +
                        (formik.touched.email && formik.errors.email
                          ? 'form-field-error'
                          : formik.touched.email && !formik.errors.email
                            ? 'form-field-success'
                            : '')
                      }
                      name="email"
                      placeholder={t('page.profile_email_placeholder')}
                      autoComplete="off"
                      type="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      readOnly={true}
                    />
                    <InputGroup.Text
                      className={
                        '' +
                        (formik.touched.email && formik.errors.email
                          ? 'form-field-error'
                          : formik.touched.email && !formik.errors.email
                            ? 'form-field-success'
                            : '')
                      }>
                      <FontAwesomeIcon onClick={() => {setShow(true);}} icon={faEdit} />
                    </InputGroup.Text>
                  </InputGroup>
                  <div className="form-field-error-text">
                    {formik.touched.email && formik.errors.email ? (
                      <div>{t(formik.errors.email)}</div>
                    ) : null}
                  </div>
                </Form.Group>
              </Col>
              <Col lg={6} md={6} xs={12}>
                <Form.Group>
                  <Form.Label className="field-label field-label-top">{t('page.profile_phone')}</Form.Label>
                  <Row>
                    <Col lg={6} xs={6}>
                      <input name="country_code" value={selectedCountryCode} style={{ visibility: 'hidden', display: 'none' }} readOnly={true} />
                      <input name="phone_code" value={selectedCountryPhoneCode} style={{ visibility: 'hidden', display: 'none' }} readOnly={true} />
                      <Select
                        name="country_id"
                        value={countryList.filter((option) => option.value === selectedCountry)}
                        onChange={(selectedOption) => {
                          setSelectedCountry(selectedOption.value);
                          formik.setFieldValue('country_id', selectedOption.value);
                          formik.setFieldValue('country_code', selectedOption.country_code);
                          formik.setFieldValue('phone_code', selectedOption.phone_code);
                        }}
                        className={
                          'country-code edit-page-country-code' +
                          (formik.touched.country_id && formik.errors.country_id
                            ? 'form-select-error'
                            : formik.touched.country_id && !formik.errors.country_id
                              ? 'form-select-success'
                              : '')
                        }
                        options={countryList}
                      />
                      <div className="form-field-error-text">
                        {formik.touched.country_id && formik.errors.country_id ? (
                          <div>{t(formik.errors.country_id)}</div>
                        ) : null}
                      </div>
                    </Col>
                    <Col lg={6} xs={6}>
                      <Form.Control
                        className={
                          'contact-number ' +
                          (formik.touched.phone && formik.errors.phone
                            ? 'form-field-error'
                            : formik.touched.phone && !formik.errors.phone
                              ? 'form-field-success'
                              : '')
                        }
                        type="text"
                        name="phone"
                        placeholder={t('page.profile_phone_placeholder')}
                        onChange={(event)=>{
                          const re = /^[0-9\b]+$/;
                          if (event.target.value === '' || re.test(event.target.value)) {
                            formik.setFieldValue('phone', event.target.value);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.phone}
                        minLength={4}
                        maxLength={16}
                      />
                      <div className="form-field-error-text">
                        {formik.touched.phone && formik.errors.phone ? (
                          <div>{t(formik.errors.phone)}</div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            </Row>
            <div className="primary-button">
              <span className="link-center" onClick={()=>{handleCloseModal('cancel')}}>{t('page.cancel_button_text')}</span>
              <TNButton
                loading={isLoading}
                type="submit"
                isdirtyform={formik.dirty && formik.dirty !== undefined ? 1 : 0}>
                {t('page.save_button_text')}
              </TNButton>
            </div>
          </Form>
        </div>
      </Card>
      {/* Update Email Modal */}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t('page.profile_email_edit_label')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mx-auto">
          <Form onSubmit={formikEmail.handleSubmit}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">{t('page.profile_email')}</Form.Label>
              <Form.Control
                className={
                  'form-field ' +
                  (formikEmail.touched.email && formikEmail.errors.email
                    ? 'form-field-error'
                    : formikEmail.touched.email && !formikEmail.errors.email
                      ? 'form-field-success'
                      : '')
                }
                type="text"
                name="email"
                placeholder={t('page.profile_email_placeholder')}
                onChange={formikEmail.handleChange}
                onBlur={formikEmail.handleBlur}
              />
              <div className="form-field-error-text">
                {formikEmail.touched.email && formikEmail.errors.email ? (
                  <div>{t(formikEmail.errors.email)}</div>
                ) : null}
              </div>
            </Form.Group>
            <div className="primary-button">
              <span className="link-center" onClick={()=>{handleCloseModal('email_cancel')}}>{t('page.cancel_button_text')}</span>
              <TNButton
                type="submit"
                loading={isLoadingEmail}
                isdirtyform={formikEmail.dirty && formikEmail.dirty !== undefined ? 1 : 0}>
                {t('page.save_button_text')}
              </TNButton>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* Email OTP Modal */}
      <Modal
        centered
        show={showVerify}
        onHide={() => { setShowVerify(false); setVerifyToken(null);}}>
        <Modal.Header closeButton>
          <Modal.Title>{t('page.profile_edit_email_verify_label')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mx-auto">
          <Form onSubmit={formikEmailVerify.handleSubmit}>
            <Form.Group>
              <Form.Label className="field-label">{t('page.profile_email_otp_label')}</Form.Label>
              <Form.Control
                className={
                  'form-field ' +
                  (formikEmailVerify.touched.otp && formikEmailVerify.errors.otp
                    ? 'form-field-error'
                    : formikEmailVerify.touched.otp && !formikEmailVerify.errors.otp
                      ? 'form-field-success'
                      : '')
                }
                type="text"
                name="otp"
                maxLength="6"
                onKeyUp={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                placeholder={t('page.profile_email_otp_placeholder')}
                onChange={formikEmailVerify.handleChange}
                onBlur={formikEmailVerify.handleBlur}
                value={formikEmailVerify.values.otp}
              />
              {formikEmailVerify.touched.otp && formikEmailVerify.errors.otp ? (
                <div className="form-field-error-text">{t(formikEmailVerify.errors.otp)}</div>
              ) : null}
            </Form.Group>
            <div className="link-right">
              <span className="resend-otp" onClick={handleResendOtp}>{t('page.resend_otp_button_button_text')}</span>
            </div>
            <div className="primary-button">
              <span className="link-center" onClick={()=>{handleCloseModal('otp_cancel')}}>{t('page.cancel_button_text')}</span>
              <TNButton
                type="submit"
                loading={isLoadingEmailVerify}
                isdirtyform={
                  formikEmailVerify.dirty && formikEmailVerify.dirty !== undefined ? 1 : 0
                }>
                {t('page.save_button_text')}
              </TNButton>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export { EditProfilePage };