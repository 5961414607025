import * as Yup from 'yup';
let validationSchema = Yup.object({
  name: Yup.string(('page.add_sprint_name_required')).trim().max(255, ('page.add_sprint_name_validation_max')).required(
    ('page.add_sprint_name_required'),
  ),
  start_date: Yup.date().nullable(),
  end_date: Yup.date().nullable(),
  status: Yup.string(('page.add_sprint_status_required')).required(
    ('page.add_sprint_status_required')
  )
});
export default validationSchema;