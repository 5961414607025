import client from '@libs/HttpClient';

class DatabaseTableService {

  //Database Table Master

  static listDatabaseTable(request) {
    const projectId = request.projectId ?? "";
    const databaseId = request.databaseId ?? "";
    const page_no = request.currentPage ?? "";
    const searchText = request.searchText ?? "";
    const status = request.selectedStatus ?? "";
    const key = request.key ?? "";
    const dateRange = request.dateRange ?? "";
    const dbFieldName = request.dbFieldName ?? "";
    return client.get(
      `/projects/view/${projectId}/database/${databaseId}/tables?search=${searchText}&page=${page_no}&status=${status}&key=${key}&date_range=${dateRange}&dbFieldName=${dbFieldName}`,
      request
    );
  }

  static addDatabaseTable(projectId, databaseId, requestData) {
    return client.post(`projects/view/${projectId}/database/${databaseId}/tables`, requestData);
  }

  static viewDatabaseTable(projectId, databaseId, id) {
    return client.get(`/projects/view/${projectId}/database/${databaseId}/tables/${id}/edit`);
  }

  static updateDatabaseTable(request) {
    const projectId = request.projectId;
    const databaseId = request.databaseId;
    const id = request.id
    return client.put(`/projects/view/${projectId}/database/${databaseId}/tables/${id}`, request);
  }

  static deleteDatabaseTable(projectId, databaseId, id) {
    return client.delete(`/projects/view/${projectId}/database/${databaseId}/tables/${id}`);
  }

  static reviewDatabase(request) {
    const projectId = request.projectId;
    const databaseId = request.databaseId;
    const id = request.id
    console.log(request, "request");
    return client.post(`/projects/view/${projectId}/database/${databaseId}/tables/${id}`, request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }

}
export { DatabaseTableService };
