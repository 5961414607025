// Project
import { useMutation, useQuery } from 'react-query';
import { ProjectService } from '@api/ProjectService';
import { onDefaultError } from '../helpers/commonHelpers';
// Records
const useListProject = ([page_no, searchText, status, managerID],onSuccess,onError = onDefaultError) => {
  return useQuery(['project-list', page_no, searchText, status, managerID], ProjectService.listProject, {
    onSuccess,
    keepPreviousData: true,
    onError
  });
};
// Create
const useAddProject = (onSuccess, onError = onDefaultError) => {
  return useMutation(ProjectService.addProject, {
    onSuccess,
    onError
  });
};
// Details
const useViewProject = (id, onSuccess, onError = onDefaultError) => {
  return useQuery('project-view', () => ProjectService.viewProject(id), {
    onSuccess,
    onError
  });
};
// Update
const useUpdateProject = (onSuccess, onError = onDefaultError) => {
  return useMutation(ProjectService.updateProject, {
    onSuccess,
    onError
  });
};
// Remove
const useDeleteProject = (onSuccess, onError = onDefaultError) => {
  return useMutation(ProjectService.deleteProject, {
    onSuccess,
    onError
  });
};
// Get Employees
const useGetEmployeeList = ([get_all],onSuccess, onError = onDefaultError) => {
  return useQuery(['employee-list', get_all] , ProjectService.employeeList, {
    onSuccess,
    onError
  });
};
// Get Clients
const useGetClientList = ([get_all],onSuccess, onError = onDefaultError) => {
  return useQuery(['client-list',get_all], ProjectService.clientList, {
    onSuccess,
    onError
  });
};
// Get Project Manager List
const useGetProjectManagerList = (onSuccess, onError = onDefaultError) => {
  return useQuery('project-manager-list', ProjectService.projectManagerList, {
    onSuccess,
    onError
  });
};
export { 
  useListProject, 
  useAddProject, 
  useViewProject, 
  useUpdateProject, 
  useDeleteProject, 
  useGetEmployeeList,
  useGetClientList,
  useGetProjectManagerList
};