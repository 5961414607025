import PropTypes from 'prop-types';
import { Footer } from '../Footer/Footer';
import '../../../../assets/scss/_custom.scss';
import { useSelector } from 'react-redux';
import { settingData } from '../../../store/features/settingSlice';
const LogoUrl = () => {
  const getSettingData = useSelector(settingData);
  return <img src={getSettingData.front_logo} alt="logo" />;
};
const PublicLayout = (props) => {
  return (
    <div {...props}>
      {props.children}
      <Footer />
    </div>
  );
};
PublicLayout.propTypes = {
  children: PropTypes.any.isRequired
};
export { PublicLayout, LogoUrl };