import client from '@libs/HttpClient';
class BranchService {
    static listUserBranch(request) {
        const page_no = request.queryKey[1];
        const searchText = request.queryKey[2];
        return client.get(`/branch?search=${searchText}&page=${page_no}`);
    }
    static viewBranch(params) {
        return client.get(`/branch/${params}/edit`);
    }
    static updateUserBranch(request) {
        return client.put(`/branch/${request.id}`, request);
    }
    static addUserBranch(request) {
        return client.post('/branch', request);
    }
    static deleteBranch(request) {
        return client.delete(`/branch/${request.id}`, request);
    }
}
export { BranchService };