import { useMutation, useQuery } from 'react-query';
import { BranchService } from '@api/BranchService';
import { onDefaultError } from '../helpers/commonHelpers';
// Records
const useListBranch = ([page_no, searchText], onSuccess, onError = onDefaultError) => {
  return useQuery(['branch-list', page_no, searchText], BranchService.listUserBranch, {
    onSuccess,
    keepPreviousData: true,
    onError
  });
};
// Create
const useAddBranch = (onSuccess, onError = onDefaultError) => {
  return useMutation(BranchService.addUserBranch, {
    onSuccess,
    onError
  });
};
// Update
const useUpdateBranch = (onSuccess, onError = onDefaultError) => {
  return useMutation(BranchService.updateUserBranch, {
    onSuccess,
    onError
  });
};
// Remove
const useDeleteBranch = (onSuccess, onError = onDefaultError) => {
  return useMutation(BranchService.deleteBranch, {
    onSuccess,
    onError
  });
};
// Details
const useViewBranch = (id, onSuccess, onError = onDefaultError) => {
  return useQuery('department-view', () => BranchService.viewBranch(id), {
    onSuccess,
    onError
  });
};
export {
  useListBranch,
  useAddBranch,
  useViewBranch,
  useUpdateBranch,
  useDeleteBranch
};