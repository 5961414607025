import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PublicLayout } from '@adminlayout';
import { loader } from '../store/features/loaderSlice';
import { loggedUser } from '../store/features/authSlice';
import Loader from '@common/components/Loader';
const PublicRoute = () => {
  /* const location = useLocation(); */
  const checkLoading = useSelector(loader);
  const checkLoggedInUser = useSelector(loggedUser);
  const isAdminLoggedIn = checkLoggedInUser.isAdminLoggedIn;
  return !isAdminLoggedIn ? (
    <PublicLayout>
      {checkLoading ? <Loader /> : ''}
      <Outlet />
    </PublicLayout>
  ) : (
    <Navigate
      to={{
        pathname: '/dashboard'
      }}
      replace
    />
  );
};
export default PublicRoute;