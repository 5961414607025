import { useMutation, useQuery } from 'react-query';
import { DepartmentService } from '@api/DepartmentService';
import { onDefaultError } from '../helpers/commonHelpers';
// Records
const useListDepartment = ( [page_no, searchText], onSuccess, onError = onDefaultError ) => {
  return useQuery(['department-list', page_no, searchText], DepartmentService.listUserDepartment, {
    onSuccess,
    keepPreviousData: true,
    onError
  });
};
// Create
const useAddDepartment = (onSuccess, onError = onDefaultError) => {
  return useMutation(DepartmentService.addUserDepartment, {
    onSuccess,
    onError
  });
};
// Update
const useUpdateDepartment = (onSuccess, onError = onDefaultError) => {
  return useMutation(DepartmentService.updateUserDepartment, {
    onSuccess,
    onError
  });
};
// Remove
const useDeleteDepartment = (onSuccess, onError = onDefaultError) => {
  return useMutation(DepartmentService.deleteDepartment, {
    onSuccess,
    onError
  });
};
// Details
const useViewDepartment = (id, onSuccess, onError = onDefaultError) => {
  return useQuery('department-view', () => DepartmentService.viewDepartment(id), {
    onSuccess,
    onError
  });
};
export {
  useListDepartment,
  useAddDepartment,
  useViewDepartment,
  useUpdateDepartment,
  useDeleteDepartment
};