import { useMutation, useQuery } from 'react-query';
import { DBFieldTypeService } from '@api/DBFieldTypeService';
import { onDefaultError } from '../helpers/commonHelpers';
// DB Field Master
// Records
const useListDBFieldType = ([page_no, searchText, status, dbfieldtype_id],onSuccess,onError = onDefaultError) => {
  return useQuery(['dbfieldtype-list', page_no, searchText, status, dbfieldtype_id], DBFieldTypeService.listDBFieldType, {
    onSuccess,
    keepPreviousData: true,
    onError
  });
};
// Create
const useAddDBFieldType = (onSuccess, onError = onDefaultError) => {
  return useMutation(DBFieldTypeService.addDBFieldType, {
    onSuccess,
    onError
  });
};
// Details
const useViewDBFieldType = (id, onSuccess, onError = onDefaultError) => {
  return useQuery('dbfieldtype-view', () => DBFieldTypeService.viewDBFieldType(id), {
    onSuccess,
    onError
  });
};
// Update
const useUpdateDBFieldType = (onSuccess, onError = onDefaultError) => {
  return useMutation(DBFieldTypeService.updateDBFieldType, {
    onSuccess,
    onError
  });
};
// Status Update
const useStatusChangeDBFieldType = (onSuccess, onError = onDefaultError) => {
  return useMutation(DBFieldTypeService.statusChangeDBFieldType, {
    onSuccess,
    onError
  });
};
// Delete
const useDeleteDBFieldType = (onSuccess, onError = onDefaultError) => {
  return useMutation(DBFieldTypeService.deleteDBFieldType, {
    onSuccess,
    onError
  });
};
export{
  //DB Field Type Master
  useListDBFieldType,
  useAddDBFieldType,
  useViewDBFieldType,
  useUpdateDBFieldType,
  useDeleteDBFieldType,
  useStatusChangeDBFieldType,
};