import { React, useState } from 'react';
import PropTypes from 'prop-types';
import { Header } from '../Header/Header';
import { Sidebar } from '../Sidebar/Sidebar';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { settingData } from '../../../store/features/settingSlice';
const LogoUrlFront = () => {
  const getSettingData = useSelector(settingData);
  return (
    <img src={getSettingData.dashboard_logo} alt="logo" width={'100%'} className="nav-brand" />
  );
};
const PrivateLayout = (props) => {
  const { t } = useTranslation();
  const [isActive, setActive] = useState(true);
  const toggleClass = () => {
    setActive(!isActive);
  };
  let bodyElement = document.getElementsByTagName('body')[0];
  bodyElement.className = isActive ? 'overly bg-white' : 'bg-white';
  return (
    <div {...props}>
      <Header isActive={isActive} toggleClass={toggleClass} t={t} />
      <div id="wrapper" className={isActive ? 'toggled' : ''}>
        <Sidebar toggleClass={toggleClass} active={isActive} t={t} />
        <section id="content-wrapper">
          <Row>
            <Col lg={12}>{props.children}</Col>
          </Row>
        </section>
      </div>
    </div>
  );
};
PrivateLayout.propTypes = {
  children: PropTypes.any.isRequired,
  active: PropTypes.string
};
export { PrivateLayout, LogoUrlFront };