import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import AuthSlice from './features/authSlice';
import loaderReducer from './features/loaderSlice';
import settingReducer from './features/settingSlice';
const persistConfig = {
  key: 'cleanmedic',
  storage,
  blacklist: []
};
const rootReducer = combineReducers({
  user: AuthSlice,
  loader: loaderReducer,
  setting: settingReducer
});
const middlewares = [thunkMiddleware];
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
  reducer: persistedReducer,
  middleware: middlewares,
  devTools: { trace: true, traceLimit: 25 }
});
let persistor = persistStore(store);
export { persistor, store };