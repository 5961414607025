import { useMutation, useQuery } from 'react-query';
import { PermissionService } from '@api/PermissionService';
import { onDefaultError } from '../helpers/commonHelpers';
// Get All Permission Records
const useGetAllPermissions = (role_id, onSuccess, onError = onDefaultError) => {
  return useQuery('permission-list', () => PermissionService.getPermissionForRoles(role_id), {
    onSuccess,
    onError
  });
};
// Assign Permission
const useAssignPermission = (onSuccess,
  onError = onDefaultError) => {
  return useMutation(PermissionService.assignPermission, {
    onSuccess,
    onError
  });
}
export {
  useGetAllPermissions,
  useAssignPermission
};