import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import ListTeamMemberPage from './ListTeamMemberPage';
const TeamMemberRoutes = ({ t }) => {
  return (
      <Route key="team_list" exact path="/view/:id/team" element={<ListTeamMemberPage t={t} />} />
  );
};
TeamMemberRoutes.propTypes = {
  t: PropTypes.func
};
export default TeamMemberRoutes;