import { useQuery } from 'react-query';
import { TeamMemberService } from '@api/TeamMemberService';
import { onDefaultError } from '../helpers/commonHelpers';
//Team Member Master
// Records
const useListTeamMember = ([ id, page_no, searchText, memberType], onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['team-member-list', id, page_no, searchText, memberType],
    () => TeamMemberService.listTeamMember({ id, page_no, searchText, memberType}),
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};
// Remove
const useDeleteTeamMember = () => {
  const deleteTeamMember = async (projectId, id) => {
    return await TeamMemberService.deleteTeamMember(projectId, id);
  };
  return { deleteTeamMember };
};
export {
  useDeleteTeamMember, 
  useListTeamMember,
};