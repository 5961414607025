import client from '@libs/HttpClient';
class DepartmentService {
    static listUserDepartment(request) {
        const page_no = request.queryKey[1];
        const searchText = request.queryKey[2];
        return client.get(`/department?search=${searchText}&page=${page_no}`);
    }
    static viewDepartment(params) {
        return client.get(`/department/${params}/edit`);
    }
    static updateUserDepartment(request) {
        return client.put(`/department/${request.id}`, request);
    }
    static addUserDepartment(request) {
        return client.post('/department', request);
    }
    static deleteDepartment(request) {
        return client.delete(`/department/${request.id}`, request);
    }
}
export { DepartmentService };