import client from '@libs/HttpClient';
class AttendanceService {
  //Attendance Master
  static listAttendance(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const id = request.queryKey[4] || '';
    return client.get(`/attendance?search=${searchText}&page=${page_no}&user_id=${id}`, request);
  }
  static addAttendance(request) {
    return client.post(`/attendance`, request);
  }
  static getAttendanceUsers(request) {
    return client.get(`/attendance/user-list`, request);
  }
  static useGetAttendanceTypeList(request) {
    return client.get(`/attendance/type-list`, request);
  }
  static viewAttendance(params) {
    return client.get(`/attendance/${params}/edit`);
  }
  static updateAttendance(request) {
    return client.put(`/attendance/${request.id}`, request);
  }
  static deleteAttendance(request) {
    return client.delete(`/attendance/${request.id}`, request);
  }
  static rejectAttendance(request) {
    return client.post(`/attendance/reject/${request}`, request);
  }
  static approveAttendance(request) {
    return client.post(`/attendance/approve/${request}`, request);
  }
}
export { AttendanceService };