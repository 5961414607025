import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
const UserList = React.lazy(() => import('./UserList'));
const AddUserPage = React.lazy(() => import('./AddUserPage'));
const EditUserPage = React.lazy(() => import('./EditUserPage'));
const ViewUserList = React.lazy(() => import('./ViewUserList'));
const UserRoutes = ({ t }) => {
  return (
    <Routes>
      <Route index exact path="/list" element={<UserList t={t} />} />
      <Route exact path="/add" element={<AddUserPage t={t} />} />
      <Route exact path="/edit/:id" element={<EditUserPage t={t} />} />
      <Route exact path="/view/:id" element={<ViewUserList t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  )
}
UserRoutes.propTypes = {
  t: PropTypes.func
};
export default UserRoutes;