import { useMutation, useQuery } from 'react-query';
import { SprintService } from '@api/SprintService';
import { onDefaultError } from '../helpers/commonHelpers';
// Records
const useListSprint = ([ id, page_no, searchText, status, startDate, endDate], onSuccess, onError = onDefaultError ) => {
  return useQuery(
    ['sprint-list', id, page_no, searchText, status, startDate, endDate],
    () => SprintService.listSprint({ id, page_no, searchText, status, startDate, endDate }),
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};
// Remove
const useDeleteSprint = () => {
  const deleteSprint = async (projectId, id) => {
    return await SprintService.deleteSprint(projectId, id);
  };
  return { deleteSprint };
};
// Create
const useAddSprint = (onSuccess, onError = onDefaultError) => {
  return useMutation(SprintService.addSprint, {
    onSuccess,
    onError
  })
}
// Update
const useUpdateSprint = (onSuccess, onError = onDefaultError) => {
  return useMutation(SprintService.updateSprint, {
    onSuccess,
    onError
  })
}
// Get Details
const useEditSprint = (projectId, id, onSuccess, onError = onDefaultError) => {
  return useQuery('sprint-edit', () => SprintService.viewSprint(projectId, id), {
    onSuccess,
    onError
  });
};
// Get Sprint Status Type
const useGetSprintStatusTypes = (onSuccess, onError = onDefaultError) => {
  return useQuery("sprint-status-type" , SprintService.sprintStatusTypes, {
    onSuccess,
    onError
  });
};
export {
  useDeleteSprint, 
  useListSprint,
  useAddSprint,
  useUpdateSprint,
  useEditSprint,
  useGetSprintStatusTypes
};