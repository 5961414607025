import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
const ListDatabaseTablePage = React.lazy(() => import('./ListDatabaseTablePage'));
const AddDatabaseTablePage = React.lazy(() => import('./AddDatabaseTablePage'));
const EditDatabaseTablePage = React.lazy(() => import('./EditDatabaseTablePage'));
const DatabaseTableRoute = ({ t }) => {
  return [
    <Route key="database_table_list" exact path="/view/:projectId/database/:databaseId/tables" element={<ListDatabaseTablePage t={t} />} />,
    <Route key="database_table_add" exact path="/view/:projectId/database/:databaseId/tables/add" element={<AddDatabaseTablePage t={t} />} />,
    <Route key="database_table_edit" exact path="/view/:projectId/database/:databaseId/tables/:id/edit" element={<EditDatabaseTablePage t={t} />} />
  ];
};
DatabaseTableRoute.propTypes = {
  t: PropTypes.func
};
export default DatabaseTableRoute;