import client from '@libs/HttpClient';
class DatabaseService {
  //Database Master
  static listDatabase(request) {
    const projectId = request.projectId;
    const page_no = request.page_no;
    const searchText = request.searchText;
    const status = request.status;
    return client.get(
      `/projects/view/${projectId}/database?search=${searchText}&page=${page_no}&status=${status}`,
      request
    );
  }
  static addDatabase(request) {
    const projectId = request.projectId;
    return client.post(`projects/view/${projectId}/database`, request);
  }
  static viewDatabase(projectId,id) {
    return client.get(`/projects/view/${projectId}/database/${id}/edit`);
}
  static updateDatabase(request) {
    const id = request.id;
    const projectId = request.project_id;
    return client.put(`/projects/view/${projectId}/database/${id}`, request);
  }
  static deleteDatabase(projectId, id) {
    return client.delete(`/projects/view/${projectId}/database/${id}`);
  }
}
export { DatabaseService };