import client from '@libs/HttpClient';
class SprintService {
  static listSprint(request) {
    const projectId = request.id;
    const page_no = request.page_no;
    const searchText = request.searchText;
    const status = request.status;
    const startDate = request.startDate;
    const endDate = request.endDate;
    return client.get(
      `/projects/view/${projectId}/sprint?search=${searchText}&page=${page_no}&sprint_status=${status}&start_date=${startDate}&end_date=${endDate}`,
      request
    );
  }
  static deleteSprint(projectId, id) {
    return client.delete(`/projects/view/${projectId}/sprint/${id}`);
  }
  static addSprint(request) {
    const projectId = request.project_id;
    return client.post(`projects/view/${projectId}/sprint`, request);
  }
  static updateSprint(request) {
    const id = request.id;
    const projectId = request.project_id;
    return client.put(`/projects/view/${projectId}/sprint/${id}`, request);
  }
  static viewSprint(projectId,id) {
    return client.get(`/projects/view/${projectId}/sprint/${id}/edit`);
  }
  static sprintStatusTypes() {
    return client.get('/get-sprint-status-type');
  }
}
export { SprintService };