// React Dependencies
import React, { useState } from 'react';
// Common Dependencies
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
// Component
import { Container, Form, InputGroup, FormControl, Button } from 'react-bootstrap';
import { PublicLayout, LogoUrl } from '@adminlayout';
import { TNButton } from '@common/components';
// Validation
import { useFormik } from 'formik';
import validationSchema from './SetPasswordValidation';
// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faClose } from '@fortawesome/free-solid-svg-icons';
// Alert
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
// API
import { useSetAllianceTypePassword } from '@hooks';
const SetPasswordPage = (props) => {
  // Props
  const { t } = props;
  let { user_id } = useParams();
  const navigate = useNavigate();
  // States
  const [isRevealPwd, setIsRevealPwd] = useState();
  const [isCnfRevealPwd, setCnfIsRevealPwd] = useState();
  // API Call
  const { mutate: doSetPassword, isLoading } = useSetAllianceTypePassword(
    (res) => {
      toast.success(res.message);
      navigate('/login');
    },
    (error) => {
      toast.error(error.message);
    }
  );
  // Cancel Form
  const handleCancel = () => {
    if (formik.dirty && formik.dirty !== undefined) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="alert-box">
              <FontAwesomeIcon
                className="alert-close"
                icon={faClose}
                onClick={() => {
                  onClose();
                }}
              />
              <div className="alert-popup">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: t('page.reset_alert_popup_message')
                  }}></h2>
                <Button
                  className="table-delete-button"
                  onClick={() => {
                    onClose();
                    navigate(`/login/`);
                  }}>
                  {t('page.alert_popup_yes_button')}
                </Button>
                <Button className="table-primary-button" onClick={onClose}>
                  {t('page.alert_popup_no_button')}
                </Button>
              </div>
            </div>
          );
        }
      });
    } else {
      navigate(`/login/`);
    }
  };
  // This Block is execute on Form Submit for validation
  const formik = useFormik({
    initialValues: {
      password: '',
      confirm_password: ''
    },
    validationSchema,
    onSubmit: (values) => {
      values.user_id = user_id;
      doSetPassword(values);
    }
  });
  return (
    <PublicLayout>
      <div className="auth-pages">
        <Container>
          <div className="background-box">
            <div>
              <div className="brand-logo">
                <LogoUrl />
              </div>
              <div>
                <h1 className="page-heading-center">{t('page.admin_set_password_header_text')}</h1>
                <div className="page-sub-heading">
                  <span>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t('page.admin_set_password_sub_header_text')
                      }}></div>
                  </span>
                </div>
              </div>
              <div>
                <Form onSubmit={formik.handleSubmit}>
                  <AlertCommon
                    key={Math.floor(Math.random() * (1000 - 1 + 1) + 1)}
                    is_error={addSPError}>
                    {addSPList}
                  </AlertCommon>
                  <div>
                    <Form.Label className="field-label field-label-top">
                      {t('page.admin_set_password_password_label')}
                    </Form.Label>
                    <InputGroup className="form-group-field">
                      <FormControl
                        className={
                          '' +
                          (formik.touched.password && formik.errors.password
                            ? 'form-field-error'
                            : formik.touched.password && !formik.errors.password
                              ? 'form-field-success'
                              : '')
                        }
                        name="password"
                        placeholder={t('page.admin_set_password_password_placeholder')}
                        autoComplete="off"
                        type={isRevealPwd ? 'text' : 'password'}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                      />
                      <InputGroup.Text
                        className={
                          '' +
                          (formik.touched.password && formik.errors.password
                            ? 'form-field-error'
                            : formik.touched.password && !formik.errors.password
                              ? 'form-field-success'
                              : '')
                        }
                        id="basic-addon1">
                        <FontAwesomeIcon
                          onClick={() => setIsRevealPwd((prevState) => !prevState)}
                          icon={isRevealPwd ? faEye : faEyeSlash}
                        />
                      </InputGroup.Text>
                    </InputGroup>
                    <div className="form-field-error-text">
                      {formik.touched.password && formik.errors.password ? (
                        <div>{t(formik.errors.password)}</div>
                      ) : null}
                    </div>
                  </div>
                  <div>
                    <Form.Label className="field-label field-label-top">
                      {t('page.admin_set_password_confirm_password_label')}
                    </Form.Label>
                    <InputGroup className="form-group-field">
                      <FormControl
                        className={
                          '' +
                          (formik.touched.confirm_password && formik.errors.confirm_password
                            ? 'form-field-error'
                            : formik.touched.confirm_password && !formik.errors.confirm_password
                              ? 'form-field-success'
                              : '')
                        }
                        name="confirm_password"
                        placeholder={t('page.admin_set_password_confirm_password_placeholder')}
                        autoComplete="off"
                        type={isCnfRevealPwd ? 'text' : 'password'}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.confirm_password}
                      />
                      <InputGroup.Text
                        className={
                          '' +
                          (formik.touched.confirm_password && formik.errors.confirm_password
                            ? 'form-field-error'
                            : formik.touched.confirm_password && !formik.errors.confirm_password
                              ? 'form-field-success'
                              : '')
                        }>
                        <FontAwesomeIcon
                          onClick={() => setCnfIsRevealPwd((prevState) => !prevState)}
                          icon={isCnfRevealPwd ? faEye : faEyeSlash}
                        />
                      </InputGroup.Text>
                    </InputGroup>
                    <div className="form-field-error-text">
                      {formik.touched.confirm_password && formik.errors.confirm_password ? (
                        <div>{t(formik.errors.confirm_password)}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="primary-button">
                    <span className="link-center" onClick={handleCancel}>
                      {t('page.admin_set_password_cancel_button_text')}
                    </span>
                    <TNButton
                      type="submit"
                      loading={isLoading}
                      isdirtyform={formik.dirty && formik.dirty !== undefined ? 1 : 0}>
                      {t('page.admin_set_password_submit_button_text')}
                    </TNButton>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </PublicLayout>
  );
};
SetPasswordPage.propTypes = {
  t: PropTypes.func,
  isdirtyform: PropTypes.any
};
export { SetPasswordPage };