// React Dependencies
import React, { useState } from 'react';
// Common Dependencies
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// Components
import { Container, NavDropdown, Navbar } from 'react-bootstrap';
// Alert
import { toast } from 'react-toastify';
// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
// Auth Components
import { logoutSuccess, loggedUser } from '../../../store/features/authSlice';
import { LogoUrlFront } from '../index';
// API Call
import { useLogout, useRouteAccessList } from '@hooks';
const Header = (props) => {
  // Navigate And Dispatch
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Route
  const [routeName, setRouteNames] = useState([]);
  useRouteAccessList((response) => {
    setRouteNames(response.data.route_name);
  });
  const shouldShowRoute = (route) => {
    if (routeName) {
      return routeName.includes(route);
    }
  };
  // Action Handlers
  const handleEditProfile = () => {
    navigate('/edit-profile');
  };
  const handleLogout = () => {
    doUserLogout();
  };
  // Auth Check
  const checkLoggedInUser = useSelector(loggedUser);
  // Props
  const { t } = props;
  const UserMenu = (
    <svg width="9" height="21" viewBox="0 0 9 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="4.44691" cy="4.49619" rx="1.44691" ry="1.49619" fill="#9592A6" />
      <ellipse cx="4.44691" cy="10.4886" rx="1.44691" ry="1.49619" fill="#9592A6" />
      <ellipse cx="4.44691" cy="16.4809" rx="1.44691" ry="1.49619" fill="#9592A6" />
    </svg>
  );
  const { mutate: doUserLogout } = useLogout(
    (response) => {
      dispatch(logoutSuccess());
      window.addEventListener('storage', (e) => {
        e.isLogout = 1;
      });
      navigate('/login');
      toast.success(response.message);
    },
    (error) => {
      window.scrollTo(0, 0);
      toast.success(error.message);
    }
  );
  return (
    <>
      <Navbar className="sticky-top navbar-section">
        <Container fluid className="container-section">
          <Navbar.Brand className="dashboard-logo">
            {/* <img src={'/admin' + LogoImage} alt="logo" width={'100%'} className="nav-brand" /> */}
            {props?.isActive ? (
              <img style={{width:"55px !important", height:"55px !important"}} src={'https://technource.s3.us-west-1.amazonaws.com/wp-content/uploads/2022/01/12110214/cropped-Technource-Icon.jpg.png'} alt="logo" className="nav-brand collapsed_logo" />
            ) : (
              <LogoUrlFront />
            )}
            <div className="dashboard-toggle-btn">
              <FontAwesomeIcon icon={faBars} onClick={() => {
                props.toggleClass();
              }} />
            </div>
          </Navbar.Brand>
          <div className="header-setting-menu">
            <div className="desktop-login-name">
              <span>
                {checkLoggedInUser.user.first_name + ' ' + checkLoggedInUser.user.last_name}
              </span>
            </div>
            <div className="profile-img">
              <img
                src={checkLoggedInUser.user.profile_image}
                width="40px"
                height="40px"
                alt="profile_img"
              />
            </div>
            <NavDropdown title={UserMenu} style={{ padding: "0.5rem 1rem" }}>
              <div className="mobile-login-name">
                <span>
                  {checkLoggedInUser.user.first_name + ' ' + checkLoggedInUser.user.last_name}
                </span>
                <NavDropdown.Divider className="separator" />
              </div>
              {shouldShowRoute('user.get-profile') &&
                <NavDropdown.Item className="setting-items" onClick={handleEditProfile}>
                  {t('page.header_editprofile')}
                </NavDropdown.Item>
              }
              {shouldShowRoute('user.logout') &&
                <NavDropdown.Item className="setting-items" onClick={handleLogout}>
                  {t('page.header_logout')}
                </NavDropdown.Item>
              }
            </NavDropdown>
          </div>
        </Container>
      </Navbar>
    </>
  );
};
Header.propTypes = {
  toggleClass: PropTypes.any.isRequired,
  t: PropTypes.func
};
export { Header };