import { useMutation, useQuery } from 'react-query';
import { UserRoleService } from '@api/UserRoleService';
import { onDefaultError } from '../helpers/commonHelpers';
// Records
const useListUserRoles = ([page_no, searchText, status],onSuccess,onError = onDefaultError) => {
  return useQuery(['role-list', page_no, searchText, status], UserRoleService.listUserRoles, {
    onSuccess,
    keepPreviousData: true,
    onError
  });
};
// Create
const useAddRole = (onSuccess, onError = onDefaultError) => {
  return useMutation(UserRoleService.addUserRoles, {
    onSuccess,
    onError
  });
};
// Details
const useViewUserRoles = (id, onSuccess, onError = onDefaultError) => {
  return useQuery('role-view', () => UserRoleService.viewUserRoles(id), {
    onSuccess,
    onError
  });
};
// Update
const useUpdateRole = (onSuccess, onError = onDefaultError) => {
  return useMutation(UserRoleService.updateUserRoles, {
    onSuccess,
    onError
  });
};
// Status Update
const useStatusChangeRole = (onSuccess, onError = onDefaultError) => {
  return useMutation(UserRoleService.statusChangeRole, {
    onSuccess,
    onError
  });
};
// Remove
const useDeleteRole = (onSuccess, onError = onDefaultError) => {
  return useMutation(UserRoleService.deleteRole, {
    onSuccess,
    onError
  });
};
export {
  useListUserRoles,
  useAddRole,
  useViewUserRoles,
  useUpdateRole,
  useDeleteRole,
  useStatusChangeRole,
};