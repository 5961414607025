import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  setting_get: true,
  dashboard_logo: '',
  front_logo: '',
  favicon_icon: '',
  email_logo: '',
  seo_title: '',
  seo_description: ''
};
export const settingSlice = createSlice({
  name: 'setting',
  initialState: initialState,
  reducers: {
    addSetting: (state, { payload }) => {
      state.dashboard_logo = payload.dashboard_logo;
      state.front_logo = payload.front_logo;
      state.favicon_icon = payload.favicon_icon;
      state.email_logo = payload.email_logo;
      state.seo_title = payload.seo_title;
      state.seo_description = payload.seo_description;
      state.setting_get = payload.setting_get;
      state.common_dirty_email_data = payload.common_dirty_email_data;
      return state;
    },
    clearSetting: () => initialState
  }
});
export const { addSetting, clearSetting } = settingSlice.actions;
export const settingData = (state) => state.setting;
export default settingSlice.reducer;