import React from 'react'
import { Route } from 'react-router-dom'
import ListTaskStatusTypePage from './ListTaskStatusTypePage'
import AddTaskStatusTypePage from './AddTaskStatusTypePage'
import EditTaskStatusTypePage from './EditTaskStatusTypePage'
const TaskStatusTypeRoutes = ({t}) => {
  return [
    <Route key={"task_status_type"} exact path="/view/:id/task-status-type" element={<ListTaskStatusTypePage t={t} />} />,
    <Route key={"task_status_add"} exact path="/view/:id/task-status-type/add" element={<AddTaskStatusTypePage t={t} />} />,
    <Route key={"task_status_edit"} exact path="/view/:id/task-status-type/:taskStatusTypeId/edit" element={<EditTaskStatusTypePage t={t} />} />
  ]
}
TaskStatusTypeRoutes.propTypes = {}
export default TaskStatusTypeRoutes