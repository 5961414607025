import React from 'react';
import Errorpage from '../../assets/images/404-page.jpg';
import '../../assets/scss/page/_notfound.scss';
import { Container, Navbar } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import DashboardLogo from '../../assets/images/technource-logo.png';
import PropTypes from 'prop-types';
import { TNButton } from '@common/components/TNButton';
const PageNotFound = (props) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/dashboard');
  };
  return (
    <>
      <Navbar className="sticky-top navbar-section">
        <Container fluid className="container-section">
          <Navbar.Brand className="dashboard-logo">
            <img src={`/${props.from}` + DashboardLogo} alt="dashboardlogo" className="nav-brand" />
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container>
        <div className="page-not-found">
          <img src={`/${props.from}` + Errorpage} alt="404" className="not-found-image" />
          <h1 className="page-heading-center">{props.t('page.not_found_oops_text')}</h1>
          <div className="error-page-text">{props.t('page.not_found_text')}</div>
          <div className="primary-button">
            <TNButton onClick={handleClick} loading={false}>
              {props.t('page.back_to_home')}
            </TNButton>
          </div>
        </div>
      </Container>
    </>
  );
};

PageNotFound.propTypes = {
  from: PropTypes.string,
  t: PropTypes.func
};

export default PageNotFound;
