import { useMutation } from 'react-query';
import { AuthService } from '@api/';
import { onDefaultError } from '../helpers/commonHelpers';
// Login
const useLogin = (onSuccess, onError = onDefaultError) => {
  return useMutation(AuthService.login, {
    onSuccess,
    onError
  });
};
// Forgot Password
const useForgotPassword = (onSuccess, onError = onDefaultError) => {
  return useMutation(AuthService.forgotPassword, {
    onSuccess,
    onError
  });
};
// Verify OTP
const useOTPVerify = (onSuccess, onError = onDefaultError) => {
  return useMutation(AuthService.otpVerify, {
    onSuccess,
    onError
  });
};
// Reset Password
const useResetPassword = (onSuccess, onError = onDefaultError) => {
  return useMutation(AuthService.resetPassword, {
    onSuccess,
    onError
  });
};
// Send OTP For Verify
const useResendOtp = (onSuccess, onError = onDefaultError) => {
  return useMutation(AuthService.resendOtp, {
    onSuccess,
    onError
  });
};
// Set Password
const useSetAllianceTypePassword = (onSuccess, onError = onDefaultError) => {
  return useMutation(AuthService.setAllianceTypePassword, {
    onSuccess,
    onError
  });
};
// Logout
const useLogout = (onSuccess, onError = onDefaultError) => {
  return useMutation(AuthService.logOutUser, {
    onSuccess,
    onError
  });
};
export {
  useLogin,
  useForgotPassword,
  useOTPVerify,
  useResetPassword,
  useResendOtp,
  useSetAllianceTypePassword,
  useLogout
};