import React from 'react'
import { Route } from 'react-router-dom'
import ListSprintPage from './ListSprintPage'
import AddSprintPage from './AddSprintPage'
import EditSprintPage from './EditSprintPage'
const SprintRoutes = ({t}) => {
  return [
    <Route key={"sprint_view"} exact path="/view/:id/sprint" element={<ListSprintPage t={t} />} />,
    <Route key={"sprint_add"} exact path="/view/:id/sprint/add" element={<AddSprintPage t={t} />} />,
    <Route key={"sprint_edit"} exact path="/view/:id/sprint/:projectSprintId/edit" element={<EditSprintPage t={t} />} />
  ]
}
SprintRoutes.propTypes = {}
export default SprintRoutes