// React Dependencies
import { useState, React, useEffect } from "react";
// Common Dependencies
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { loggedUser } from '../../../store/features/authSlice';
// Component
import { Form, Card, Row, Col, Badge, Button } from 'react-bootstrap';
import { TNButton } from '@common/components';
import { ProjectNavigation } from '../Navigation/ProjectNavigation';
// CK Editor
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'axios';
// import makeAnimated from 'react-select/animated';
import Select from 'react-select';
// Validation
import { useFormik } from 'formik';
// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faFloppyDisk, faTrash, faXmark, faClose } from '@fortawesome/free-solid-svg-icons';
// Moment
import moment from "moment";
// API
import {
  useRouteAccessList,
  useGetTaskDetails, useGetSprintList,
  useGetTaskPriorities,
  useGetTaskStatusTypes,
  useGetAssignedProjectEmployeeList,
  useUpdateAssignees,
  useEditTask,
  // Task Comment
  useListTaskComment,
  useAddTaskComment,
  useUpdateTaskComment,
  useDeleteTaskComment,
} from '@hooks';
// Alert
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
// Asset
import '@assets/scss/page/_taskView.scss';
// Image Popup
import { Lightbox } from "react-modal-image";
const ViewTaskPage = ({ t, socket }) => {
  // Socket Initialization
  useEffect(() => {
    socket.on("get-latest-comment", () => {
      refetch();
    })
  }, [])
  // Auth Data
  const checkLoggedInUser = useSelector(loggedUser);
  // Navigate
  const navigate = useNavigate();
  // Project ID And Task ID
  let { id,
    projectTaskId } = useParams();
  // Animated Component Set
  // const animatedComponents = makeAnimated();
  // States
  const [taskDetails, setTaskDetails] = useState(null);
  const [routeName, setRouteNames] = useState([]);
  const [selectedAssignees, setSelectedAssignees] = useState("");
  const [showAssigneeDropDown, setShowAssigneeDropDown] = useState(false);
  // Comment, Work Log And History Tab
  const [userData, setUserData] = useState(null);
  const [activeTab, setActiveTab] = useState(1);
  const [showEditor, setShowEditor] = useState(false);
  const [commentDescription, setCommentDescription] = useState("");
  const [commentsData, setCommentsData] = useState([]);
  const [projectTaskCommentIdValue, setProjectTaskCommentIdValue] = useState(null);
  const [popupImage, setPopupImage] = useState(null);
  // Dropdown Lists
  // Sprint List
  const { data: optionList, isLoading: isSprintLoading } = useGetSprintList(id);
  let options = [];
  if (optionList !== undefined && !isSprintLoading) {
    optionList.data.map((val) => {
      options.push({
        value: val.project_sprint_id,
        label: val.name
      });
      return true;
    });
  }
  // Task Priorities List
  const { data: taskPriorityList, isLoading: isTaskPriorityLoading } = useGetTaskPriorities();
  let options2 = [];
  if (taskPriorityList !== undefined && !isTaskPriorityLoading) {
    Object.entries(taskPriorityList).map(([value, label]) => {
      options2.push({
        value: parseInt(value),
        label: label
      });
      return true;
    });
  }
  // Task Status Type List
  const { data: taskStatusTypeList, isLoading: isTaskStatusTypeLoading } = useGetTaskStatusTypes(id);
  const taskStatusTypeOptions = [];
  if (taskStatusTypeList !== undefined && !isTaskStatusTypeLoading) {
    taskStatusTypeList.data.map((val) => {
      taskStatusTypeOptions.push({
        value: val.task_status_type_id,
        label: val.status_name
      });
      return true;
    });
  }
  // Get Employee List
  const { data: employeeList, isLoading: isUserLoading } = useGetAssignedProjectEmployeeList(id);
  const options1 = [];
  if (employeeList !== undefined && !isUserLoading) {
    employeeList.data.employee_list.map((val) => {
      options1.push({
        value: val.user_id,
        label: val.first_name + ' ' + val.last_name
      });
      return true;
    });
  }
  // Task Details Fetch
  useGetTaskDetails(id, projectTaskId, ({ data: taskData }) => {
    if (taskData) {
      setTaskDetails(taskData);
      let assigneesArray = [];
      taskData?.assignee.forEach(object => {
        assigneesArray.push({ value: object?.user_id, label: object?.full_name });
      });
      setSelectedAssignees(assigneesArray);
    }
  },
    (error) => {
      if (error?.data?.redirect == true) {
        toast.error(error.message);
        navigate('/dashboard');
      }else{
        toast.error(error.message);
      }
    });
  // Edit And List Handlers
  const handleEditClick = (id, taskId) => {
    navigate(`/projects/view/${id}/task/${taskId}/edit`);
  };
  useRouteAccessList((res) => {
    setRouteNames(res.data.route_name);
  });
  const shouldShowRoute = (route) => {
    if (routeName) {
      return routeName.includes(route);
    }
  };
  // Update Task API
  const { mutate: updateTaskAssignees } = useUpdateAssignees((response) => {
    toast.success(response.message);
    let assigneesArray = [];
    selectedAssignees.forEach(object => {
      assigneesArray.push({ "full_name": object.label })
    });
    setTaskDetails({
      ...taskDetails,
      assignee: assigneesArray
    });
    setShowAssigneeDropDown(!showAssigneeDropDown)
  });
  // Task Data Initialize On Load
  useEditTask(id, projectTaskId, ({ data: taskData }) => {
    if (taskData) {
      if (taskData.assignee) {
        taskData.assignee.map((val) => {
          formik.values.assignee_ids.push({
            value: val.user_id,
            label: val.first_name + ' ' + val.last_name
          });
          return formik.values.assignee_ids;
        });
      }
    }
  },
    (error) => {
      if (error?.data?.redirect == true) {
        toast.error(error.message);
        navigate('/dashboard');
      }else{
        toast.error(error.message);
      }
    });
  // Form Validation And Submit
  const formik = useFormik({
    initialValues: {
      project_id: id,
      id: projectTaskId,
      assignee_ids: []
    },
    onSubmit: (values) => {
      updateTaskAssignees(values);
    }
  });
  // Comments, Work Log And History
  // Profile Image
  // Board View Profile Image
  const getProfileImageURL = (userId, profileImage) => {
    if (profileImage) {
      let imageURL = `${process.env.MIX_AWS_URL}user_profile/${userId}/${profileImage}`;
      return imageURL;
    } else {
      return false;
    }
  }
  const getImageName = (firstName, lastName) => {
    let imageName = "US";
    if (firstName) {
      imageName = firstName.substring(0, 1);
    }
    if (lastName) {
      imageName += lastName.substring(0, 1);
    }
    return imageName;
  }
  // CK Editor Image Upload Adapter
  // Image Uploader
  const uploadAdapter = (loader) => {
    return {
      upload: async () => {
        const file = await loader.file;
        const data = new FormData();
        data.append('upload', file);
        const url = "api/ckeditor/img";
        const response = await axios.post(url, data);
        return {
          default: response.data
        }
      },
    };
  };
  function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }
  // Set Logged In Custom User Data
  useEffect(() => {
    if (checkLoggedInUser && checkLoggedInUser?.user) {
      let userInfo = checkLoggedInUser?.user;
      userInfo = JSON.parse(JSON.stringify(userInfo));
      userInfo['full_name'] = userInfo?.first_name + " " + userInfo?.last_name;
      userInfo['image_name'] = getImageName(userInfo?.first_name, userInfo?.last_name)
      // userInfo['profile_image'] = "";
      setUserData(userInfo);
    }
  }, [checkLoggedInUser]);
  // Create Comment API
  const { mutate: createCommentData } = useAddTaskComment((response) => {
    toast.success(response.message);
    setShowEditor(false);
    setCommentDescription("");
    refetch();
    socket.emit('comment-updated', "");
  }, (error) => {
    console.log(error, "Error");
  });
  // Update Comment API
  const { mutate: updateCommentData } = useUpdateTaskComment((response) => {
    toast.success(response.message);
    setShowEditor(false);
    setCommentDescription("");
    refetch();
    setProjectTaskCommentIdValue(null);
    socket.emit('comment-updated', "");
  }, (error) => {
    console.log(error, "Error");
  });
  // Fetch Records
  const { refetch } = useListTaskComment([id, projectTaskId],
    (res) => {
      let newCommentsData = [];
      res?.data.forEach(object => {
        let newCommentObject = object;
        let commentDateTime = newCommentObject?.updated_at ?? newCommentObject?.created_at;
        newCommentObject['commentDateTime'] = moment(commentDateTime).format("MMMM D, YYYY [at] h:mm A");
        let newUserObject = newCommentObject?.get_user_info ?? {};
        newUserObject['full_name'] = newUserObject?.first_name + " " + newUserObject?.last_name;
        newUserObject['image_name'] = getImageName(newUserObject?.first_name, newUserObject?.last_name)
        if (newUserObject?.profile_image) {
          newUserObject['profile_image'] = getProfileImageURL(newUserObject?.user_id, newUserObject?.profile_image);
        } else {
          newUserObject['profile_image'] = "";
        }
        newCommentObject['get_user_info'] = newUserObject;
        const images = extractImages(newCommentObject?.comment);
        newCommentObject['images'] = images;
        newCommentsData.push(newCommentObject);
      });
      setCommentsData(newCommentsData);
    },
    (error) => {
      console.log(error, "Error");
    }
  );
  // Create Comment
  const createComment = () => {
    let commentData = {
      project_id: id,
      project_task_id: projectTaskId,
      comment: commentDescription
    }
    createCommentData(commentData);
  }
  // Scroll To Comment
  const scroll = () => {
    const section = document.querySelector('#userCommentInputDiv');
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  // Get Comment Data For Update
  const getCommentDataForUpdate = (commentData) => {
    setProjectTaskCommentIdValue(commentData?.project_task_comments_id);
    setCommentDescription(commentData?.comment);
    setShowEditor(true);
    scroll();
  };
  // Update Comment
  const updateComment = () => {
    let commentData = {
      project_id: id,
      project_task_id: projectTaskId,
      project_task_comments_id: projectTaskCommentIdValue,
      comment: commentDescription
    }
    updateCommentData(commentData);
  }
  // Delete Comment
  const { deleteTaskComment } = useDeleteTaskComment();
  const handleDeleteClick = (id, projectTaskId, projectTaskCommentId) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="alert-box">
            <FontAwesomeIcon className="alert-close" icon={faClose} onClick={() => { onClose(); }} />
            <div className="alert-popup">
              <h2>{t('page.delete_task_status_type_alert_popup_message')}</h2>
              <TNButton
                className="table-delete-button"
                onClick={async () => {
                  try {
                    const response = await deleteTaskComment(id, projectTaskId, projectTaskCommentId);
                    if (response && response.message) {
                      toast.success(response.message);
                    }
                    onClose();
                    refetch();
                    socket.emit('comment-updated', "");
                  } catch (error) {
                    toast.error(error);
                  }
                }}>
                {t('page.alert_popup_yes_button')}
              </TNButton>
              <TNButton className="table-primary-button" onClick={onClose}>
                {t('page.alert_popup_no_button')}
              </TNButton>
            </div>
          </div>
        );
      },
    });
  }
  // Image Popup
  const extractImages = (content) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    return Array.from(doc.querySelectorAll('img')).map(img => img.src);
  };
  return (
    <>
      <ProjectNavigation t={t} id={id} activeLabel='Tasks' />
      {
        taskDetails && (
          <Card className="inner-box">
            <div>
              <Row>
                <Col lg={12} xs={12} className='taskViewDetailsDiv'>
                  <Row>
                    <Col lg={11} xs={11}>
                      <h4>{taskDetails.name}</h4>
                    </Col>
                    <Col lg={1} xs={1}>
                      {shouldShowRoute('projects.task.edit') &&
                        <FontAwesomeIcon style={{ float: "right" }} className="alert-close" icon={faEdit} onClick={() => { handleEditClick(id, projectTaskId) }} />
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4} xs={12}>
                      <h5>
                        <Badge key={"sprint"} className='sprintInfo'>
                          {options ? options.filter(function (obj) { return obj.value === taskDetails?.project_sprint_id; }).map(function (obj) { return obj.label; })[0] : ""}
                        </Badge>
                      </h5>
                    </Col>
                    <Col lg={8} xs={12}>
                      <Row>
                        <Col lg={11} xs={11}>
                          <div style={{ float: "right" }}>
                            <h5>
                              <Badge key={"priority"}
                                className={
                                  taskDetails?.priority === 3 ? "highPriority" : taskDetails?.priority === 2 ? "mediumPriortity" : "lowPriortity"
                                }
                              >
                                {options2 ? options2.filter(function (obj) { return obj.value === taskDetails?.priority; }).map(function (obj) { return obj.label; })[0] : ""}
                              </Badge>
                              {" | "}
                              <Badge key={"task_status_info"} className='taskStatusInfo'>
                                {taskStatusTypeOptions ? taskStatusTypeOptions.filter(function (obj) { return obj.value === taskDetails?.task_status_type_id; }).map(function (obj) { return obj.label; })[0] : ""}
                              </Badge>
                              {
                                taskDetails?.assignee && taskDetails?.assignee?.length > 0 ? taskDetails?.assignee?.map((row, index) => {
                                  return (
                                    <div style={{ float: "right" }} className="assignees_badges_div" key={`assignees_div_${index}`}>
                                      {" | "}
                                      <Badge
                                        className="assigneesBadge"
                                        key={`assignee_${index}`}
                                      >
                                        {row.full_name}
                                      </Badge>
                                    </div>
                                  )
                                }) : ""
                              }
                            </h5>
                          </div>
                        </Col>
                        <Col lg={1} xs={1}>
                          {
                            shouldShowRoute('projects.task.update-assignee') && (
                              <FontAwesomeIcon style={{ float: "right", cursor: "pointer" }} icon={!showAssigneeDropDown ? faEdit : faXmark} onClick={() => { setShowAssigneeDropDown(!showAssigneeDropDown) }} />
                            )
                          }
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {showAssigneeDropDown && shouldShowRoute('projects.task.update-assignee') && (
                    <>
                      <Row>
                        <Col lg={12} xs={12}>
                          <Form onSubmit={formik.handleSubmit}>
                            <Row>
                              <Col lg={11} xs={11}>
                                <div style={{ float: "right", marginRight: "-38px" }}>
                                  <Select
                                    className="assigneeDropDown"
                                    placeholder={t('page.select_assignee_placeholder')}
                                    options={options1.sort((a, b) => a.label.localeCompare(b.label, 'en', { numeric: true }))}
                                    onChange={(selectedOptions) => {
                                      setSelectedAssignees([selectedOptions]);
                                      formik.setFieldValue('assignee_ids', [selectedOptions]);
                                    }}
                                    value={selectedAssignees}
                                  />
                                </div>
                              </Col>
                              <Col lg={1} xs={1}>
                                <TNButton className="saveAssignee" type="submit" style={{ float: "right" }} >
                                  <FontAwesomeIcon isdirtyform={1} icon={faFloppyDisk} />
                                </TNButton>
                              </Col>
                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </>
                  )}
                  <br />
                  <h5 dangerouslySetInnerHTML={{ __html: taskDetails.description }}></h5>
                </Col>
              </Row>
              {
                shouldShowRoute('projects.task.comment.index') ? (
                  <Row className="subCommentsHistoryTabs">
                    <Col lg={12} xs={12}>
                      {
                        shouldShowRoute('projects.task.comment.index') && (
                          <span onClick={() => { setActiveTab(1) }} className={activeTab == 1 ? "active" : ""}>Comments</span>
                        )
                      }
                      <span onClick={() => { setActiveTab(2) }} className={activeTab == 2 ? "active" : ""}>Work Logs</span>
                      <span onClick={() => { setActiveTab(3) }} className={activeTab == 3 ? "active" : ""}>Work History</span>
                      <Row>
                        {
                          shouldShowRoute('projects.task.comment.index') && activeTab == 1 && userData && (
                            <Col className="tabDiv" lg={12} xs={12}>
                              {
                                shouldShowRoute('projects.task.comment.store') && (
                                  <Row id="userCommentInputDiv">
                                    <Col lg={1} xs={1}>
                                      {
                                        userData?.profile_image ? (
                                          <div data-toggle="tooltip" data-placement="top" title={userData?.full_name} className="profileImageWithImage">
                                            <img src={userData?.profile_image} />
                                          </div>
                                        ) : (
                                          <div data-toggle="tooltip" data-placement="top" title={userData?.full_name} className="profileImageWithoutImage">
                                            {userData?.image_name}
                                          </div>
                                        )
                                      }
                                    </Col>
                                    <Col lg={6} xs={6}>
                                      {
                                        !showEditor ? (
                                          <Form.Group>
                                            <Form.Control
                                              className="editorPlaceHolder"
                                              placeholder={t('page.task_comment_placeholder')}
                                              onClick={() => { setShowEditor(true) }}
                                            />
                                          </Form.Group>
                                        ) : (
                                          <>
                                            <Form.Group>
                                              <CKEditor
                                                editor={ClassicEditor}
                                                config={
                                                  {
                                                    extraPlugins: [uploadPlugin],
                                                    placeholder: t('page.task_comment_placeholder')
                                                  }
                                                }
                                                data={commentDescription}
                                                onReady={(editor) => {
                                                  // You can store the "editor" and use when it is needed.
                                                  console.log('Editor is ready to use!', editor);
                                                }}
                                                onChange={(event, editor) => {
                                                  const data = editor.getData();
                                                  setCommentDescription(data);
                                                }}
                                              />
                                            </Form.Group>
                                            <Button type="button" onClick={() => { projectTaskCommentIdValue ? updateComment() : createComment() }} className="btn btn-primary editorButton">
                                              {t('page.task_comment_create')}
                                            </Button>
                                            <span className="editorCancelButton" onClick={() => { setShowEditor(false); setCommentDescription(""); }}>
                                              {t('page.cancel_button_text')}
                                            </span>
                                          </>
                                        )
                                      }
                                    </Col>
                                  </Row>
                                )
                              }
                              {
                                commentsData && commentsData?.map((object, index) => {
                                  return (
                                    <Row className="userComment" key={`user_comments_${index}`}>
                                      <Col lg={1} xs={1}>
                                        {
                                          object?.get_user_info?.profile_image ? (
                                            <div data-toggle="tooltip" data-placement="top" title={object?.get_user_info?.full_name} className="profileImageWithImage">
                                              <img src={object?.get_user_info?.profile_image} />
                                            </div>
                                          ) : (
                                            <div data-toggle="tooltip" data-placement="top" title={object?.get_user_info?.full_name} className="profileImageWithoutImage">
                                              {object?.get_user_info?.image_name}
                                            </div>
                                          )
                                        }
                                      </Col>
                                      <Col lg={6} xs={6}>
                                        <div className="userInformation">
                                          <h5>{object?.get_user_info?.full_name}</h5>
                                          <p>
                                            {object?.commentDateTime}
                                            {
                                              shouldShowRoute('projects.task.comment.destroy') && (
                                                userData?.user_id == object?.get_user_info?.user_id ||
                                                userData?.user_type == 1
                                              )
                                                ? (
                                                  <>
                                                    <FontAwesomeIcon className="alert-close actionButton deleteButton" icon={faTrash} onClick={() => { handleDeleteClick(id, projectTaskId, object?.project_task_comments_id); }} />
                                                  </>
                                                ) : ""
                                            }
                                            {
                                              shouldShowRoute('projects.task.comment.update') &&
                                                userData?.user_id == object?.get_user_info?.user_id ? (
                                                <>
                                                  <FontAwesomeIcon className="alert-close actionButton editButton" icon={faEdit} onClick={() => { getCommentDataForUpdate(object) }} />
                                                </>
                                              ) : ""
                                            }
                                          </p>
                                        </div>
                                        <h6 className="commentInfo" dangerouslySetInnerHTML={{ __html: object.comment }}></h6>
                                        <Row className="commentBoxImage">
                                          {
                                            object?.images && object?.images?.map((object, index) => {
                                              return (
                                                <Col className="imageBox" key={`image_box_${index}`} lg={2} xs={2}>
                                                  <img onClick={() => { setPopupImage(object) }} src={object} />
                                                </Col>
                                              )
                                            })
                                          }
                                        </Row>
                                      </Col>
                                    </Row>
                                  )
                                })
                              }
                            </Col>
                          )
                        }
                        {
                          activeTab == 2 && (
                            <Col className="tabDiv" lg={12} xs={12}>
                              Work Log Div
                            </Col>
                          )
                        }
                        {
                          activeTab == 3 && (
                            <Col className="tabDiv" lg={12} xs={12}>
                              History Div
                            </Col>
                          )
                        }
                        {
                          popupImage && (
                            <Lightbox
                              hideDownload={true}
                              medium={popupImage}
                              large={popupImage}
                              alt="Comment Box Image"
                              onClose={() => { setPopupImage(null) }}
                            />
                          )
                        }
                      </Row>
                    </Col>
                  </Row>
                ) : ""
              }

            </div>
          </Card>
        )
      }
    </>
  );
};
ViewTaskPage.propTypes = {
  t: PropTypes.func,
};
export default ViewTaskPage;