import React from 'react';
import ReactDOM from 'react-dom/client';
import '../locales/i18n';
import App from './pages/App';
import reportWebVitals from '@libs/reportWebVitals';
const rootElement = document.getElementById('app');
const root = ReactDOM.createRoot(rootElement);
root.render(<App />);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();